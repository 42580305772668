import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { ProgressHttpModule } from 'angular-progress-http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgGridModule } from 'angular2-grid';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DpDatePickerModule } from 'ng2-date-picker';
import * as moment from 'moment';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { DndModule } from 'ng2-dnd';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { TimelineComponent } from './timeline/timeline.component';
import { CompanyDialog } from './dialogs/company-dialog.component';
import { ScreenDialog } from './dialogs/screen-dialog.component';
import { MenuDialog } from './dialogs/menu-dialog.component';
import { LocationDialogComponent } from './dialogs/location-dialog.component';
import { SpecialDateDialog } from './dialogs/special-date-dialog.component';
import { LocationsComponent } from './locations/locations.component';
import { MediaElementsComponent } from './media-elements/media-elements.component';
import { SearchComponent } from './search/search.component';
import { TimelineRoutingModule } from './timeline/timeline-routing.module';
import { HeaderComponent } from './header/header.component';
import { MediaElementDialog } from './dialogs/media-element-dialog';
import { UpdatePromptDialog } from './dialogs/update-prompt-dialog.component';
import { ConfirmDeleteDialog } from './dialogs/confirm-delete-dialog.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { HttpModule } from '@angular/http';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  ApiService,
  UserService,
  SharedModule,
  FooterComponent,
  LocationsService,
  TimetableService,
  MediaService,
  CompaniesService,
  MediaFilterPipe,
  AutoFocusDirective,
  AfterStartTimePipe,
  ArraySortPipe,
  ListErrorsComponent,
  FirebaseService,
  FireFunctionsApiService,
  FFMPEGService
} from './shared';
import { OverviewComponent } from './overview/overview.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyChooserComponent } from './company-chooser/company-chooser.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { FirestoreApiService } from './shared/services/firestore.api.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConnectComponent } from './connect/connect.component';
import { AuthGuard } from './auth/auth.guard';
import {GlobalHttpInterceptorService} from './shared/services/global-http-interceptor.service';
import {ToastrModule} from 'ngx-toastr';
import {SliderComponent} from './slider/slider.component';
import { FilepickerComponent } from './filepicker/filepicker.component';
import { DashboardGuard } from './dashboard/dashboard.guard';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ScreenCapturePreviewComponent } from './screen-capture-preview/screen-capture-preview.component';
import { QRCodeModule } from 'angular2-qrcode';
import { registerLocaleData, LOCATION_INITIALIZED } from '@angular/common';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import localeDeCh from '@angular/common/locales/de-CH';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeItCh from '@angular/common/locales/it-CH';
import { VideoComponent } from './video/video.component';
import { FavoriteMediasFoldersDialogComponent } from './dialogs/favorite-medias-folders-dialog/favorite-medias-folders-dialog.component';
import { PresetMediaElementComponent } from './preset-media-element/preset-media-element.component';
import { Ng2ImgToolsModule } from 'ng2-img-tools';
import { ModuleUploaderComponent } from './module/module-uploader/module-uploader.component';

registerLocaleData(localeDeCh);
registerLocaleData(localeEnGb);
registerLocaleData(localeFrCh);
registerLocaleData(localeItCh);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

// wait with app start until 18n stuff has been loaded (enables translate.instant())
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en-GB';
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ListErrorsComponent,
    FooterComponent,
    HeaderComponent,
    TimelineComponent,
    SpecialDateDialog,
    ScreenDialog,
    CompanyDialog,
    LocationDialogComponent,
    MediaElementDialog,
    UpdatePromptDialog,
    ConfirmDeleteDialog,
    MenuDialog,
    LocationsComponent,
    SearchComponent,
    MediaElementsComponent,
    MediaFilterPipe,
    AutoFocusDirective,
    AfterStartTimePipe,
    OverviewComponent,
    DashboardComponent,
    ArraySortPipe,
    CompanyChooserComponent,
    ConnectComponent,
    SliderComponent,
    FilepickerComponent,
    ScreenCapturePreviewComponent,
    VideoComponent,
    FavoriteMediasFoldersDialogComponent,
    PresetMediaElementComponent,
    ModuleUploaderComponent,
  ],
  imports: [
    BrowserModule,
    ProgressHttpModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    AppRoutingModule,
    AuthRoutingModule,
    TimelineRoutingModule,
    SharedModule,
    FlexLayoutModule,
    NgGridModule,
    PerfectScrollbarModule,
    DpDatePickerModule,
    HttpModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DndModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    MatTabsModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatSliderModule,
    MatTooltipModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWithDelay' }),
    ToastrModule.forRoot(),
    QRCodeModule,
    DragDropModule,
    Ng2ImgToolsModule
  ],
  providers: [
        // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [TranslateService, Injector],
    //   multi: true
    // },
    AuthGuard,
    DashboardGuard,
    ApiService,
    UserService,
    LocationsService,
    TimetableService,
    MediaService,
    FFMPEGService,
    CompaniesService,
    FirebaseService,
    FireFunctionsApiService,
    FirestoreApiService,
    {provide: MAT_DATE_LOCALE, useValue: 'de-CH'},
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SpecialDateDialog,
    MediaElementDialog,
    UpdatePromptDialog,
    ConfirmDeleteDialog,
    MenuDialog,
    ScreenDialog,
    CompanyDialog,
    LocationDialogComponent,
    LocationsComponent,
    SearchComponent,
    MediaElementsComponent,
    FavoriteMediasFoldersDialogComponent
  ]
})
export class AppModule { }
