import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'afterStartTime'
})
export class AfterStartTimePipe implements PipeTransform {
    transform(items: Array<any>, startTime: number): Array<any> {
        if(!startTime) {startTime = 0;}
        return items.filter(item => item.hour + item.minutes / 100 > startTime);
    }
}
