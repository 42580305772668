
import {distinctUntilChanged, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { User } from '../models';
import { Router } from '@angular/router';

@Injectable()
export class UserService {
  public currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public localUserData: any;

  constructor (
    private apiService: ApiService,
    private router: Router
  ) {
    this.localUserData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.localUserData) {
      this.setAuth(this.localUserData);
    }
  }

  setAuth(user: User) {
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  userLogin(credentials): Observable<User> {
    return this.apiService.post('/Token', credentials, 'form', false).pipe(
    map(
      data => {
        this.setAuth(data);
        localStorage.setItem('currentUser', JSON.stringify(data));
        return data;
      }
    ), catchError((error) => {
      return throwError(error);
    }));
  }

  forgotPasswordRequest(credentials) {
    return this.apiService.post('/api/Account/ForgotPassword', credentials, 'form', false).pipe(
    map(
      data => {
        return data;
      }
    ));
  }

  forgotPasswordReset(credentials) {
    return this.apiService.post('/api/Account/ResetPassword', credentials, 'form', false).pipe(
    map(
      data => {
        return data;
      }
    ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    this.currentUserSubject.next(new User());
    this.isAuthenticatedSubject.next(false);
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('auth/login');
  }
}
