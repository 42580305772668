import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Media, MediaService } from '../shared';
import { FavoriteMediasFolder } from '../shared/models/favoriteMediasFolder.model';

@Component({
  selector: 'app-preset-media-element',
  templateUrl: './preset-media-element.component.html',
  styleUrls: ['./preset-media-element.component.scss']
})
export class PresetMediaElementComponent implements OnInit {

  dragData = {
    media: null,
    folder: null,
  };

  @Input() media: Media;
  @Input() folder: FavoriteMediasFolder;
  @Input() leftMenuTabIndex: number;

  constructor(
    private mediaService: MediaService
  ) { }

  ngOnInit() {
    this.dragData.media = this.media;
    this.dragData.folder = this.folder;
  }

  toggleFavorite(media, index, event) {
    console.log("hey")
    event.stopPropagation(); // prevent click event on underlying card
    event.preventDefault();
    this.mediaService.toggleFavorite(media, index).subscribe(() => { });
  }

}
