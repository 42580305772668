import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface ShematicIpsumObj {
  id: string;
  name: string;
  email: string;
  bio: string;
  age: number;
  avatar: string;
}

@Component({
  selector: 'app-filepicker',
  templateUrl: './filepicker.component.html',
  styleUrls: ['./filepicker.component.scss']
})
export class FilepickerComponent implements OnInit {

  loading = {
    files: false
  };
  error = {
    files: null
  };
  files: ShematicIpsumObj[];

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getExampleImageArray();
  }

  getExampleImageArray() {
    this.loading.files = true;
    const body = {
      'type': 'object',
      'properties': {
        'id': {
          'type': 'string',
          'ipsum': 'id'
        },
        'name': {
          'type': 'string',
          'ipsum': 'name'
        },
        'email': {
          'type': 'string',
          'format': 'email'
        },
        'bio': {
          'type': 'string',
          'ipsum': 'sentence'
        },
        'age': {
          'type': 'integer'
        },
        'avatar': {
          'type': 'string',
          'ipsum': 'small image'
        }
      }
    };
    const headersConfig = {
      'Content-Type': 'application/json'
    };
    this.http.post('http://schematic-ipsum.herokuapp.com/?n=' + 10, body, {
      headers: new HttpHeaders(headersConfig)
    }).subscribe(
      (res: ShematicIpsumObj[]) => {
        this.files = res;
        this.loading.files = false;
      }, (err) => {
        this.error.files = err;
        this.loading.files = false;
      }
    );
  }

}
