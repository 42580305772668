import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Company, CompaniesService } from '../shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-chooser',
  templateUrl: './company-chooser.component.html',
  styleUrls: ['./company-chooser.component.scss']
})
export class CompanyChooserComponent implements OnInit, OnDestroy {
  companySubscription: any;
  company: Company;
  companiesSubscription: any;
  companies = [];

  // @Input() companies;

  constructor(
    private companiesService: CompaniesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.companiesSubscription = this.companiesService.allCompanies.subscribe(
      (companies) => {
        this.companies = companies;
      }
    );
    this.companySubscription = this.companiesService.company.subscribe(
      value => {
        this.company = value;
      }
    );
  }

  ngOnDestroy() {
    // this.companiesSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
  }

  onClickCompany(company: Company) {
    this.company = company;
    this.companiesService.company.next(company);
    if (localStorage.getItem('uuid')) {
      this.router.navigateByUrl('/connect/' + localStorage.getItem('uuid'));
      return;
    }
    this.router.navigateByUrl('/overview/' + company.companyId);
  }



}
