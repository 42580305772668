import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { User, UserService, TimetableService, MediaService, ApiService, CompaniesService, FirebaseService } from '../shared';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpdatePromptDialog } from './update-prompt-dialog.component';
import { appLog } from '../shared/utils/app-log.util';
import { MediaUsage } from '../shared/models/media-usage.model';
import { Router } from '@angular/router';
import { ModuleCustomConfig } from '../shared/models/module-custom-config.model';
import { ModuleWeekday } from '../shared/models/module-weekday.model';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Ng2ImgToolsService } from 'ng2-img-tools';

@Component({
    selector: 'media-element-dialog',
    templateUrl: 'media-element-dialog.html',
    styleUrls: ['./media-element-dialog.scss']
})
export class MediaElementDialog implements OnInit, OnDestroy {
    currentUser: User = new User();
    company = {
        companyId: undefined
    };
    startTime = {
        name: undefined,
        minutes: undefined,
        hour: undefined
    };
    stopTime = {
        name: undefined,
        minutes: undefined,
        hour: undefined
    };
    startTimes = [];
    stopTimes = [];
    media = [];
    mediaType: string;
    days = [];
    day = {
        label: '',
        timeTableType: '',
        timeTableValue: ''
    };
    showSpecialTimes = false;
    selectedDate = undefined;
    datepickerConf = {
        dayBtnCssClassCallback: function (day) {
            let cssClasses;
            return null;
        },
        locale: 'de-ch'
    };
    showMediaUsage = false;
    loadingMediaUsage = false;
    mediaUsage: MediaUsage[] = [];
    isBlocked = false;
    saving = false;
    timetable = [];
    newMediaItems = [];
    mergedMediaItems = [];
    savingMediaItem = 0;
    selectedMediaItem = {
        idx: -1,
        isNew: false
    };
    showSeconds = 4;
    animationDuration = 230;
    showSecondsChanged = false;
    transitionSeconds = 0.5;
    uploading = false;
    uploadingProgress = 0;
    hasFile = false;
    showPreview = false;
    previewMedia = [];
    existingMediaItems = [];
    toDeleteMediaItems = [];
    customConfig: ModuleCustomConfig = {};
    togglingFavourite = false;
    disallowedFileExt: any;
    showFilePicker = true;
    moduleUploaders: any;

    mensamaxMenu: any;
    mensamaxImage0: any;
    mensamaxImage1: any;
    mensamaxMenu$: any;
    mensamaxImage0$: any;
    mensamaxImage1$: any;
    menuplanId: any;
    wrongMenuplanID = false;
    syncingMenuplan = false;
    wrongMenuFormat = false;
    isSorted = false;



    constructor(
        public dialogRef: MatDialogRef<MediaElementDialog>,
        public userService: UserService,
        public companiesService: CompaniesService,
        public mediaService: MediaService,
        public apiService: ApiService,
        public timetableService: TimetableService,
        public dialog: MatDialog,
        private router: Router,
        private firebase: FirebaseService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ng2ImgToolsService: Ng2ImgToolsService
    ) {
    }

    ngOnInit() {
        this.buildStartTimes();
        this.buildStopTimes();
        this.days = this.timetableService.days;
        this.day = this.days[this.timetableService.timeTabIndex.value];
        this.data.box.day = this.day;
        this.timetable = this.timetableService.timetableSubject.value;
        this.currentUser = this.userService.getCurrentUser();
        if (this.data.showMediaUsage) { this.onShowMediaUsage(); }
        this.showSpecialTimes = this.data.showSpecialTimes;
        this.selectedDate = this.data.selectedDate;
        if (this.data.box.stopTime && this.data.box.startTime) { this.colisionCheck(); }
        if (this.data.media.mediaItems && !this.data.media.customType) {
            this.hasFile = true;
            if (this.data.media.mediaItems.length) { this.showSeconds = this.data.media.mediaItems[0].showSeconds; }
            this.makeMergedMediaItems();
        } else if (this.data.media.customType) {
            // Module customConfig handling
            if (this.data.media.mediaItems) {
                if (this.data.media.mediaItems[0].config) {
                    const config = JSON.parse(this.data.media.mediaItems[0].config);
                    this.customConfig = config;

                }
            }
            // module specific initialization handling
            // Mensamax
            if (this.data.media.mediaTypeId === 'acb8d1bf-1fb8-4076-8b54-079e95a051a5') {
                if (!this.customConfig.weekdays) {
                    this.makeWeekdays();
                }
                // build uploaders for daymenu
                this.moduleUploaders = [];
                const id = this.customConfig.menuplanId;
                if (id) {
                    this.moduleUploaders = [
                        { 'id': '1', uploading: false, signedUrl: null, deleting: false },
                        { 'id': '2', uploading: false, signedUrl: null, deleting: false }
                    ];
                }
                this.mensamaxMenu$ = this.firebase.mensamaxMenu.subscribe((m) => {
                    this.mensamaxMenu = m;
                    if (m) {
                        if (!m.body.Wochenspeiseplaene) {
                            this.wrongMenuplanID = true;
                            this.syncingMenuplan = false;
                            return;
                        } else if (!m.body.Wochenspeiseplaene[0]
                            || !m.body.Wochenspeiseplaene[0].Tagesspeiseplaene[0]
                            || !m.body.Wochenspeiseplaene[0].Tagesspeiseplaene[0].TagesspeiseplanPositionen[0]
                            || !m.body.Wochenspeiseplaene[0].Tagesspeiseplaene[0].TagesspeiseplanPositionen[0].Menue) {
                            this.wrongMenuFormat = true;
                            this.syncingMenuplan = false;
                            return;
                        } else {
                            this.syncingMenuplan = false;
                            this.wrongMenuplanID = false;
                            this.wrongMenuFormat = false;
                            this.moduleUploaders = [
                                { 'id': '1', uploading: false, signedUrl: null },
                                { 'id': '2', uploading: false, signedUrl: null }
                            ];
                        }
                        this.moduleUploaders[0].title = m.body.Wochenspeiseplaene[0].Tagesspeiseplaene[0].TagesspeiseplanPositionen[0].Menuegruppe.Bezeichnung;
                        this.moduleUploaders[1].title = m.body.Wochenspeiseplaene[0].Tagesspeiseplaene[0].TagesspeiseplanPositionen[1].Menuegruppe.Bezeichnung;
                    }
                });
                this.mensamaxImage0$ = this.firebase.mensamaxImage0.subscribe((m) => {
                    this.mensamaxImage0 = m;
                    if (m) {
                        this.moduleUploaders[0].signedUrl = m.signedUrl + '&v=' + new Date().getTime();
                        this.moduleUploaders[0].uploading = false;
                    }
                });
                this.mensamaxImage1$ = this.firebase.mensamaxImage1.subscribe((m) => {
                    this.mensamaxImage1 = m;
                    if (m) {
                        this.moduleUploaders[1].signedUrl = m.signedUrl + '&v=' + new Date().getTime();
                        this.moduleUploaders[1].uploading = false;
                    }

                });
                this.firebase.getMensaMaxMenuData(id);
                this.firebase.getMensaMaxMenuImage(id, '0');
                this.firebase.getMensaMaxMenuImage(id, '1');

            }
        } else {
            this.data.media.mediaItems = [];
        }
    }

    ngOnDestroy() {
        if (this.data.media.mediaTypeId === 'acb8d1bf-1fb8-4076-8b54-079e95a051a5') {
            this.mensamaxMenu$.unsubscribe();
            this.mensamaxImage0$.unsubscribe();
            this.mensamaxImage1$.unsubscribe();
            this.firebase.mensamaxMenu.next(null);
            this.firebase.mensamaxImage0.next(null);
            this.firebase.mensamaxImage1.next(null);
        }

    }

    makeMergedMediaItems() {
        const merged = [];
        // add them items existing
        merged.push(...this.data.media.mediaItems);
        // add them new items
        merged.push(...this.newMediaItems);
        // sort them items by sortNrs
        merged.sort((a, b) => a.sortNr > b.sortNr ? 1 : ((b.sortNr > a.sortNr) ? -1 : 0));
        this.mergedMediaItems = merged;
    }

    saveMergedMediaItems() {
        this.mergedMediaItems.forEach((m, i) => {
            m.sortNr = i;
            this.mediaService.updateMediaItem(m).subscribe(() => {
                if (i === this.mergedMediaItems.length - 1) {
                    this.dialogRef.close(this.data);
                }
            });
        });
    }

    dropMediaItem(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.mergedMediaItems, event.previousIndex, event.currentIndex);
        this.isSorted = true;
    }

    onSyncMensaMax() {
        this.moduleUploaders = null;
        this.wrongMenuFormat = false;
        this.wrongMenuplanID = false;
        this.syncingMenuplan = true;
        const id = this.customConfig.menuplanId;
        this.firebase.syncMensaMaxToFirestore(id).subscribe((res) => {
            this.firebase.getMensaMaxMenuData(id);
            this.firebase.getMensaMaxMenuImage(id, '0');
            this.firebase.getMensaMaxMenuImage(id, '1');
        });
    }

  

    makeWeekdays() {
        const days: ModuleWeekday[] = [];
        days.push({ id: 1, name: 'Montag', value: true });
        days.push({ id: 2, name: 'Dienstag', value: true });
        days.push({ id: 3, name: 'Mittwoch', value: true });
        days.push({ id: 4, name: 'Donnerstag', value: true });
        days.push({ id: 5, name: 'Freitag', value: true });
        days.push({ id: 6, name: 'Samstag', value: true });
        days.push({ id: 7, name: 'Sonntag', value: true });
        this.customConfig.weekdays = days;
    }

    onShowMediaUsage() {
        if (!this.showMediaUsage) {
            this.showMediaUsage = true;
            if (!this.mediaUsage.length) {
                this.loadingMediaUsage = true;
                this.mediaService.getMediaUsage(this.data.media.mediaId)
                    .subscribe(
                        data => {
                            this.loadingMediaUsage = false;
                            this.mediaUsage = data;
                        },
                        () => {
                            this.loadingMediaUsage = false;
                        }
                    );
            }
        } else {
            this.showMediaUsage = false;
        }

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onChangeDay() {
        const idx = this.days.indexOf(this.day);
        this.data.box.day = this.day;
        this.timetableService.changeDay(this.data.activeScreenId, idx)
            .subscribe(
                data => {
                    if (this.data.box.stopTime && this.data.box.startTime) { this.colisionCheck(); }
                }
            );
    }
    onChangeStartTime() {
        if (this.data.box.stopTime) { this.colisionCheck(); }
        this.data.box.startTime = this.startTime;
    }
    onChangeStopTime() {
        if (this.data.box.startTime) { this.colisionCheck(); }
        this.data.box.stopTime = this.stopTime;
    }
    onChangeSelectedDate() {
        this.data.selectedDate = this.selectedDate;
        this.timetableService.changeDate(this.data.activeScreenId, this.selectedDate)
            .subscribe(
                data => {
                    if (this.data.box.stopTime && this.data.box.startTime) { this.colisionCheck(); }
                }
            );
    }

    colisionCheck() {
        this.isBlocked = false; // reset flag
        this.isBlocked = this.timetableService.colisionCheck(this.startTime, this.stopTime, this.data.box.timeTableId);
    }

    onTapMediaUsage(mediaUsage: MediaUsage) {
        const companyId = this.companiesService.company.value.companyId;
        const url = 'timeline/' + companyId + '/' + mediaUsage.locationId + '/' + mediaUsage.screenId;
        setTimeout(() => {
            location.reload();
        }, 100);
        this.router.navigateByUrl(url);
    }

    onSave() {
        this.saving = true;
        const m = {
            'mediaDescription': this.data.media.mediaDescription,
            'mediaTypeId': this.data.media.mediaTypeId,
            'mediaItemSlideType': this.data.media.mediaItemSlideType,
            'isFavourite': this.data.media.isFavourite,
            'mediaId': this.data.media.mediaId
        };
        if (!this.isBlocked) {
            this.save(m);
        }
    }

    save(m) {
        // delete mediaItems from delete Object
        if (this.toDeleteMediaItems.length && !this.newMediaItems.length) {
            this.preSaveDeleteMediaItems(m, this.toDeleteMediaItems);
        } else {
            this.executeSave(m);
        }
    }



    preSaveDeleteMediaItems(m, items) {
        this.mediaService.deleteMediaItems(items).subscribe(
            (res) => {
                this.executeSave(m);
            }, (err) => {
                console.log('deletion error', err);
            }
        );
    }


    deleteMediaItem(item) {
        this.mediaService.deleteMediaItem(item.mediaItemId).subscribe(
            (res) => {
                // deleted
            }
        );
    }

    executeSave(m) {

        if (this.data.media.mediaId && (this.data.media.multipleCount > 0)) {
            // Open Dialog: Update or New Item?
            const promptRef = this.dialog.open(UpdatePromptDialog, {
                width: '380px',
                disableClose: true,
                data: {
                    overwrite: false
                }
            });
            promptRef.afterClosed().subscribe(result => {
                if (result) {
                    if (result.overwrite) {
                        // Update Media
                        appLog('test');
                        this.updateMedia(m);
                    } else {
                        // Create Media (copy mediaItems)
                        m.mediaId = undefined;
                        this.existingMediaItems = this.data.media.mediaItems;
                        // make sure the new item is not automatically a favourite
                        m.isFavourite = false;
                        this.createMedia(m);
                    }
                }
            });
        } else { // else if this media is new (no id), or if it exists only once
            if (this.data.media.mediaId) {
                // Update Media
                this.updateMedia(m);
            } else {
                // Create Media
                this.createMedia(m);
            }
        }
    }

    createMedia(m) {
        this.mediaService.createMedia(m)
            .subscribe(
                data => {
                    const toggleFav = this.data.media.toggleFavoriteAfterCreate;
                    this.data.media = data.result[0];
                    if (this.data.media.customType) {
                        this.createCustomMediaItems();
                    } else {
                        this.createMediaItems();
                    }
                    // Toggle Favorite if flag is set
                    if (toggleFav) {
                        this.toggleFavorite();
                    }
                },
                err => {
                    this.saving = false;
                }
            );
    }

    updateMedia(m) {
        this.mediaService.updateMedia(m)
            .subscribe(
                data => {
                    this.data.media = data.result[0];
                    if (this.data.media.customType) {
                        this.createCustomMediaItems();
                    } else {
                        // if it is a slider, we create the media items (POST MediaItems)
                        // if it is a video or a image we need to update the previous media item by mediaItemId (PUT MediaItems)
                        if (this.data.media.mediaTypeName === 'Slider') {
                            this.createMediaItems();
                        } else {
                            if (this.toDeleteMediaItems[0] && this.newMediaItems[0]) {
                                const item = {
                                    'mediaItemId': this.toDeleteMediaItems[0].mediaItemId,
                                    'mediaId': this.newMediaItems[0].mediaId,
                                    'config': this.newMediaItems[0].config,
                                    'showSeconds': this.newMediaItems[0].showSeconds,
                                    'filename': this.newMediaItems[0].filename,
                                    'companyId': this.newMediaItems[0].companyId,
                                    'sortNr': this.newMediaItems[0].sortNr
                                };
                                this.updateMediaItem(item);
                            } else {
                                this.createMediaItems();
                            }
                        }
                    }
                },
                err => {
                    this.saving = false;
                }
            );
    }

    createMediaItems() {
        if (this.showSecondsChanged && this.data.media.mediaItems) {
            const leng = this.data.media.mediaItems.length;
            for (let i = 0; i < leng; i++) {
                this.data.media.mediaItems[i].showSeconds = this.showSeconds;
                this.updateMediaItem(this.data.media.mediaItems[i]);
            }
        }
        if (this.newMediaItems.length || this.existingMediaItems.length) {
            const mediaItems = [];
            // Push existing mediaItems
            for (let i = 0; i < this.existingMediaItems.length; i++) {
                const item = {
                    'mediaId': this.data.media.mediaId,
                    'config': null,
                    'showSeconds': this.showSeconds,
                    'filename': this.existingMediaItems[i].filename,
                    'companyId': this.data.company.companyId,
                    'sortNr': 1
                };
                mediaItems.push(item);
            }
            // Push new mediaItems
            for (let i = 0; i < this.newMediaItems.length; i++) {
                const item = {
                    'mediaId': this.data.media.mediaId,
                    'config': null,
                    'showSeconds': this.showSeconds,
                    'filename': this.newMediaItems[i].filename,
                    'companyId': this.data.company.companyId,
                    'sortNr': 1
                };
                mediaItems.push(item);
            }
            // create mediaItems
            const leng = mediaItems.length;
            for (let i = 0; i < leng; i++) {
                this.savingMediaItem++;
                this.mediaService.createMediaItem(mediaItems[i])
                    .subscribe(
                        data => {
                            const item = this.mergedMediaItems.filter((m) => m.filename === data.filename)[0];
                            this.mergedMediaItems.forEach((m) => {
                                if (m.filename === data.filename) {
                                    m.mediaItemId = data.mediaItemId;
                                    m.publicUrl = data.publicUrl;
                                    m.mediaId = data.mediaId;
                                    m.filename = data.filename;
                                    m.companyId = data.companyId;
                                }
                            })
                            if (i === leng - 1) {
                                if (this.isSorted) {
                                    this.saveMergedMediaItems();
                                } else {
                                    this.dialogRef.close(this.data);
                                }

                            }
                        }
                    );
            }
        } else {
            if (this.isSorted) {
                this.saveMergedMediaItems();
            } else {
                this.dialogRef.close(this.data);
            }

        }
    }

    createCustomMediaItems() {
        if (!this.data.media.mediaItems.length) {
            const mediaItems = [];
            // Custom Module Handling
            if (this.data.media.customType) {
                const item = {
                    'mediaId': this.data.media.mediaId,
                    'config': JSON.stringify(this.customConfig),
                    'companyId': this.data.company.companyId
                };
                mediaItems.push(item);
            }
            const leng = mediaItems.length;
            for (let i = 0; i < leng; i++) {
                this.savingMediaItem++;
                this.mediaService.createMediaItem(mediaItems[i])
                    .subscribe(
                        data => {
                            if (i === leng - 1) { this.dialogRef.close(this.data); }
                        }
                    );
            }
        } else {
            const leng = this.data.media.mediaItems.length;
            for (let i = 0; i < leng; i++) {
                if (this.data.media.customType) {
                    this.data.media.mediaItems[i].config = JSON.stringify(this.customConfig);
                }
                this.updateMediaItem(this.data.media.mediaItems[i]);
            }
            this.dialogRef.close(this.data);
        }
    }

    updateMediaItem(item) {
        this.mediaService.updateMediaItem(item)
            .subscribe(
                data => {
                    this.dialogRef.close(this.data);
                }
            );
    }

    onDeleteMediaItem(item, index) {
        if (item.mediaItemId) {
            this.toDeleteMediaItems.push(item);
            this.mergedMediaItems.splice(index, 1);
            this.data.media.mediaItems.splice(this.data.media.mediaItems.indexOf(item), 1);
        } else {
            this.mergedMediaItems.splice(index, 1);
            this.newMediaItems.splice(this.newMediaItems.indexOf(item), 1);
        }
    }


    returnFileExt(filename: string) {
        // get file extension
        const splitted = filename.split('.');
        return splitted[splitted.length - 1].toLowerCase();
    }

    onFileSelect(event) {
        const mediaTypeName = this.data.media.mediaTypeName;
        // Video and Image MediaElements have only one MediaItem
        // When uploading a new MediaItem (File), make sure to delete the old one
        if (mediaTypeName === 'Video' || mediaTypeName === 'Image') {
            if (this.data.media.mediaItems.length) {
                this.toDeleteMediaItems.push(this.data.media.mediaItems[0]);
                this.data.media.mediaItems.splice(0, 1);
            }
        }



        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            const formData: FormData = new FormData();
            const mb = file.size / 1000000;
            // currently we dont allow file upload of files greater than 100MB
            if (mb > 100) {
                alert('Maximum File Size is currently 100MB, the file you selected has: ' + file.size / 1000000 + 'MB - and therefore cannot be uploaded');
                return;
            }

            // get file extension
            const fileExt = this.returnFileExt(file.name);

            this.disallowedFileExt = null;
            if (mediaTypeName === 'Video' && fileExt.toLowerCase() !== 'mp4') {
                this.disallowedFileExt = fileExt;
                return;
            }
            if ((mediaTypeName === 'Image') && fileExt !== 'jpg') {
                this.disallowedFileExt = fileExt;
                return;
            }
            if ((mediaTypeName === 'Slider') && (fileExt !== 'jpg' && fileExt.toLowerCase() !== 'mp4')) {
                this.disallowedFileExt = fileExt;
                return;
            }
            this.uploadingProgress = 0;
            this.uploading = true;

            // upload jpg
            if ((mediaTypeName === 'Image' || mediaTypeName === 'Slider') && fileExt === 'jpg') {
                formData.append('uploadFile', file, file.name);
                this.mediaService.uploadFileWithProgress(formData, this.data.company.companyId).subscribe(
                    (res) => {
                        if (res.loaded && res.total) {
                            this.uploadingProgress = (100 / res.total) * res.loaded;
                            this.hasFile = true;
                        }
                        if (res.status === 200) {
                            this.uploading = false;
                        }
                        if (res.body) {
                            this.addMediaItem(res.body);
                        }
                    }
                );
            }

            // upload mp4
            if ((mediaTypeName === 'Video' || mediaTypeName === 'Slider') && fileExt.toLowerCase() === 'mp4') {
                formData.append('', file, file.name);
                this.mediaService.uploadVideoWithProgress(formData, this.data.company.companyId).subscribe(
                    (res) => {
                        if (res.loaded && res.total) {
                            this.uploadingProgress = (100 / res.total) * res.loaded;
                            this.hasFile = true;
                        }
                        if (res.status === 200) {
                            this.uploading = false;
                        }
                        if (res.body) {
                            this.addMediaItem(res.body);
                        }
                    }
                );
            }

        }
    }


    addMediaItem(file) {
        const mediaItem = {
            'mediaId': this.data.media.mediaId,
            'config': null,
            'showSeconds': this.showSeconds,
            'filename': file.fileName,
            'companyId': this.data.company.companyId,
            'sortNr': this.mergedMediaItems.length + 1,
            'publicUrl': file.publicUrl
        };
        if (this.data.media.mediaTypeName === 'Slider') {
            this.newMediaItems.push(mediaItem);
        } else {
            // this.data.media.mediaItems[0] = mediaItem;
            this.newMediaItems[0] = mediaItem;
        }
        this.makeMergedMediaItems();
    }

    onSelectMediaItem(index, isNew) {
        if (this.selectedMediaItem.idx === index && this.selectedMediaItem.isNew === isNew) {
            this.selectedMediaItem.idx = undefined;
            this.selectedMediaItem.isNew = false;
        } else {
            this.selectedMediaItem.idx = index;
            this.selectedMediaItem.isNew = isNew;
        }
    }

    onChangeShowSeconds(event) {
        this.showSecondsChanged = true;
        this.mergedMediaItems.forEach((m) => {
            m.showSeconds = this.showSeconds;
        })
    }

    onChangeAnimationDuration(event) {

    }

    toggleFavorite() {
        if (this.data.media.mediaId) {
            this.togglingFavourite = true;
            this.mediaService.toggleFavorite(this.data.media, undefined)
                .subscribe(
                    data => {
                        this.togglingFavourite = false;
                        // toggl done
                    },
                    err => {
                        this.togglingFavourite = false;
                    }
                );
        } else {
            if (typeof (this.data.media.toggleFavoriteAfterCreate) === 'undefined') {
                this.data.media.toggleFavoriteAfterCreate = true;
            } else {
                this.data.media.toggleFavoriteAfterCreate = !this.data.media.toggleFavoriteAfterCreate;
            }
        }
    }

    openMediaPreview(media) {
        if (!this.showPreview) {
            this.showPreview = true;
            this.previewMedia = media;
        } else {
            this.showPreview = false;
            this.previewMedia.length = 0;
        }
    }

    buildStartTimes(): void {
        const t = [];
        let stopTimeFlag = false;
        // 24 hours in half hour takt
        // starting at 00:00
        // stopping at 23:30
        for (let i = 0; i < 24; i++) {
            const hour1 = {
                name: (i < 10 ? '0' : '') + i + ':00',
                minutes: 0,
                hour: i
            };
            t.push(hour1);
            const hour2 = {
                name: (i < 10 ? '0' : '') + i + ':30',
                minutes: 30,
                hour: i
            };
            t.push(hour2);
            if (stopTimeFlag) {
                stopTimeFlag = false;
                this.stopTime = hour1;
                this.data.box.stopTime = hour1;
            }
            if (this.data.selectedTime.name) {
                if (hour1.name === this.data.selectedTime.name) {
                    if (this.data.selectedTime.halfHour === 'first') {
                        this.startTime = hour1;
                        this.stopTime = hour2;
                        this.data.box.startTime = hour1;
                        this.data.box.stopTime = hour2;
                    }
                    if (this.data.selectedTime.halfHour === 'second') {
                        this.startTime = hour2;
                        this.data.box.startTime = hour2;
                        stopTimeFlag = true;
                    }
                }
            }
        }
        this.startTimes = t;
        if (this.data.box.startTime) {
            this.startTime = this.findTime(this.data.box.startTime.hour, this.data.box.startTime.minutes, 'start');
        }
    }

    buildStopTimes(): void {
        const t = [];
        // 24 hours in half hour takt
        // starting at 00:30
        // stopping at 24:00
        for (let i = 0; i < 24; i++) {
            if (i > 0) {
                const hour1 = {
                    name: (i < 10 ? '0' : '') + i + ':00',
                    minutes: 0,
                    hour: i
                };
                t.push(hour1);
            }
            const hour2 = {
                name: (i < 10 ? '0' : '') + i + ':30',
                minutes: 30,
                hour: i
            };
            t.push(hour2);
            if (i === 23) {
                const hour3 = {
                    name: (i + 1) + ':00',
                    minutes: 0,
                    hour: i + 1
                };
                t.push(hour3);
            }
        }

        this.stopTimes = t;
        if (this.data.box.startTime && !this.data.box.stopTime) {
            this.data.box.stopTime = {
                name: '24:00',
                minutes: 0,
                hour: 24
            };
        }

        if (this.data.box.stopTime) {
            this.stopTime = this.findTime(this.data.box.stopTime.hour, this.data.box.stopTime.minutes, 'stop');
        }
    }

    findTime(h, m, type) {
        const times = (type === 'start' ? this.startTimes : this.stopTimes);
        let time, leng;
        leng = times.length;
        for (let i = 0; i < leng; i++) {
            if (h === times[i].hour && m === times[i].minutes) {
                time = times[i];
                break;
            }
        }
        return time;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    getPlayTime(d) {
        const date = new Date(d);
        return date.toTimeString().split(' ')[0].slice(0, -3);
    }

    getPlayDay(t) {
        let day;
        if (t === 10) { day = 'Main'; }
        if (t === 20) { day = 'Week'; }
        if (t === 30) { day = 'Special'; }
        if (t === 5) { day = 'Module'; }
        return day;
    }

    removeMedia(media, index: number): void {
        this.timetableService.deleteFromTimetable(media.screenId, media.timeTableType, media.timeTableValue, media.timeTableId)
            .subscribe(
                data => {
                    if (this.mediaUsage[index]) {
                        this.mediaUsage.splice(index, 1);
                    }
                }
            );
    }

    showUploadExplainer() {
        if (this.newMediaItems) {
            return false;
        }
        if (this.newMediaItems.length > 0) {
            return false;
        }
        const mediaItems = this.data.media.mediaItems;
        if (mediaItems) {
            return false;
        }
        if (mediaItems.length > 0) {
            return false;
        }

        return true;
    }

    disabledCheck() {
        // Yoojis Module
        if (this.data.media.mediaTypeId === 'b5911ab1-6e5d-44ac-a25e-7c401bea834f') {
            if (!this.startTime.name || !this.stopTime.name || !this.customConfig.restaurantId || this.isBlocked) {
                return true;
            } else {
                return false;
            }
        }
        // MensaMax Module
        if (this.data.media.mediaTypeId === 'acb8d1bf-1fb8-4076-8b54-079e95a051a5') {
            if (!this.startTime.name || !this.stopTime.name || !this.customConfig.menuplanId || this.isBlocked) {
                return true;
            } else {
                return false;
            }
        }
        // Person Counter Module
        if (this.data.media.mediaTypeId === 'f8f87057-42e3-44a8-adee-a05be2776fae') {
            if (!this.startTime.name || !this.stopTime.name || this.isBlocked || !this.customConfig.maxPersonsAmount) {
                return true;
            } else {
                return false;
            }
        }
        // Default
        if (!this.day.label || !this.startTime.name || !this.stopTime.name || this.isBlocked || !this.hasFile || this.uploading) {
            return true;
        } else {
            return false;
        }
    }

}
