import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Errors, UserService, FirebaseService } from '../shared';
import { trigger, transition, useAnimation, query, style, stagger, animate, keyframes } from '@angular/animations';
import { bounce, bounceInDown, bounceInUp, slideInDown } from 'ng-animate';
import { MatSnackBar } from '@angular/material';


const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    trigger('logoAnimation', [transition('void => *', useAnimation(slideInDown, { params: { timing: 0.2, delay: 0 } }))]),
    trigger('formAnimation', [transition('void => 1', useAnimation(bounceInDown, { params: { timing: 1.2, delay: 0.3 } }))]),
  ]
})
export class AuthComponent implements OnInit {
  authType: String = '';
  title: String = '';
  errors: Errors = new Errors();
  isSubmitting = false;
  authForm: FormGroup;
  forgotPasswordRequestSent = false;
  userId: String = '';
  confirmCode: String = '';
  passwordResetted = false;

  // animation triggers
  bounce: any;
  authBoxAnimation: any;
  logoAnimation: any;
  formAnimation: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar
  ) {
    // use FormBuilder to create a form group
    this.authForm = this.fb.group({
      'username': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.logoAnimation = 'init';
    this.formAnimation = 'init';

    this.route.url.subscribe(data => {
      // Get the last piece of the URL (it's either 'login' or 'register')
      this.authType = data[data.length - 1].path;
      // Set a title for the page accordingly
      this.title = (this.authType === 'login') ? 'Sign In' : 'Sign Up';
      // add form control for username if this is the register page
      if (this.authType === 'login') {
        this.authForm.addControl('password', new FormControl('', Validators.required));
        this.authForm.addControl('username', new FormControl('', Validators.required));
      }
      if (this.authType === 'reset-password') {
        this.authForm.addControl('password', new FormControl('', Validators.required));
      }
      if (this.authType === 'reset-password') {
        this.authForm.removeControl('username');
      }
      if (this.authType === 'forgot') {
        this.authForm.removeControl('password');
      }
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.userId = params['userId'];
      this.confirmCode = params['confirmCode'];
    });
  }

  submitForm(form) {
    if (!form.valid) { return; }
    this.isSubmitting = true;
    this.errors = new Errors();
    if (this.authType === 'login') {
      const credentials = `username=${this.authForm.value.username}&password=${this.authForm.value.password}&grant_type=password`;
      this.userService.userLogin(credentials)
        .subscribe(
          data => {
            if (localStorage.getItem('uuid')) {
              this.router.navigateByUrl('/connect/' + localStorage.getItem('uuid'));
            } else {
              if (data.companyId) {
                this.router.navigateByUrl('overview/' + data.companyId);
              } else {
                this.router.navigateByUrl('dashboard');
              }
            }        
          },
          err => {
            this.errors = err;
            this.isSubmitting = false;
          }
        );
    }
    if (this.authType === 'forgot') {
      const credentials = `username=${this.authForm.value.username}`;
      this.userService.forgotPasswordRequest(credentials)
        .subscribe(
          data => {
            this.forgotPasswordRequestSent = true;
            this.isSubmitting = false;
          },
          err => {
            this.errors = err;
            this.forgotPasswordRequestSent = true;
            this.isSubmitting = false;
          }
        );
    }
    if (this.authType === 'reset-password') {
      const credentials = `newPassword=${this.authForm.value.password}&userId=${this.userId}&confirmCode=${this.confirmCode}`;
      this.userService.forgotPasswordReset(credentials)
        .subscribe(
          data => {
            this.passwordResetted = true;
            this.isSubmitting = false;
          },
          err => {
            this.errors = err;
            this.isSubmitting = false;
          }
        );
    }
  }

}
