import {map, distinctUntilChanged, delay, catchError, switchMap, mergeMap, takeLast} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, of, from, Observable} from 'rxjs';
import {NgGridItemConfig} from 'angular2-grid';
import * as moment from 'moment';
import {ApiService} from './api.service';
import {CompaniesService} from './companies.service';
import {Screen} from '../models/screen.model';
import {FirebaseService} from './firebase.service';
import { TimetableEntry } from '../models/timetable.model';

@Injectable()
export class TimetableService {
  public timetableSubject = new BehaviorSubject([]);
  public timetable = this.timetableSubject.asObservable().pipe(distinctUntilChanged());
  public timeTabIndex = new BehaviorSubject<number>(0);
  public leftMenuTabIndex = new BehaviorSubject<number>(0);
  public selectedDate = new BehaviorSubject(moment());
  public activeScreenId = new BehaviorSubject<number>(null);
  public activeScreen = new BehaviorSubject<Screen>(null);
  public selectedDates = new BehaviorSubject([]);
  public createdTimeBoxes = new BehaviorSubject([]);
  public toCreateTimeBoxes = new BehaviorSubject([]);
  public duplicate = new BehaviorSubject(false);

  public boxes = [];
  public days = [
    {label: 'Standard', name: 'Standard', timeTableValue: null, timeTableType: 'Main', bgColor: 'rgb(36, 80, 14)'},
    {label: 'Mo.', name: 'Montag', timeTableValue: 1, timeTableType: 'Week', bgColor: '#000'},
    {label: 'Di.', name: 'Dienstag', timeTableValue: 2, timeTableType: 'Week', bgColor: '#000'},
    {label: 'Mi.', name: 'Mittwoch', timeTableValue: 3, timeTableType: 'Week', bgColor: '#000'},
    {label: 'Do.', name: 'Donnerstag', timeTableValue: 4, timeTableType: 'Week', bgColor: '#000'},
    {label: 'Fr.', name: 'Freitag', timeTableValue: 5, timeTableType: 'Week', bgColor: '#000'},
    {label: 'Sa.', name: 'Samstag', timeTableValue: 6, timeTableType: 'Week', bgColor: '#000'},
    {label: 'So.', name: 'Sonntag', timeTableValue: 0, timeTableType: 'Week', bgColor: '#000'},
  ];

  constructor(
    private apiService: ApiService,
    private companiesService: CompaniesService,
    private firebaseService: FirebaseService
  ) { }

  getTimetable(screenId) {
    this.activeScreenId.next(screenId);
    const day = this.days[this.timeTabIndex.value];
    return this.apiService.get('/api/Timetables/' + screenId + '/' + day.timeTableType + '/' + day.timeTableValue, 'json', true).pipe(
      map(
        data => {
          this.timetableSubject.next(data);
          this.updateBoxesWithTimetable();

          return (data);
        }
      ));
  }

  getSingleScreen() {
    const id = this.activeScreenId.value;
    if (!id) {return; }
    return this.apiService.get('/api/Screens/' + id, 'json', true).pipe(
      map(
        data => {
          this.activeScreen.next(data);
          return (data);
        }
      ));
  }

  getTimetableWithIndex(screenId, index) {
    const day = this.days[index];
    return this.apiService.get('/api/Timetables/' + screenId + '/' + day.timeTableType + '/' + day.timeTableValue, 'json', true);
  }

  reloadTimetable(screenId) {
    this.activeScreenId.next(screenId);
    const idx = this.timeTabIndex.value;
    const t = this.days[idx].timeTableType;
    const v = this.days[idx].timeTableValue;
    return this.apiService.get('/api/Timetables/' + screenId + '/' + t + '/' + v, 'json', true).pipe(
      map(
        data => {
          this.timetableSubject.next(data);
          this.updateBoxesWithTimetable();
          return (data);
        }
      ));
  }

  getUsedDates(screenId) {
    return this.apiService.get('/api/Screens/' + screenId + '/SpecialDateSelector', 'json', true).pipe(
      map(
        data => {
          return (data);
        }
      ));
  }

  getSpecialTimetable(screenId, selectedDate?) {
    this.activeScreenId.next(screenId);
    const date = selectedDate ? selectedDate : this.selectedDate.value.format('YYYY-MM-DD');
    return this.apiService.get('/api/Timetables/' + screenId + '/Special/' + date, 'json', true).pipe(
      map(
        data => {
          // prevent visual timetable update if function is triggered with selectedDate (delete all timetable entries special date)
          if (!selectedDate) {
            this.timetableSubject.next(data);
            this.updateBoxesWithTimetable();
          }
          return (data);
        }
      ));
  }



  duplicateSpecialTimetable() {
    const toCreate = this.toCreateTimeBoxes.value;
    this.duplicate.next(null);
    return from(toCreate).pipe(
      mergeMap((d) => {
        return this.apiService.post('/api/Timetables/', d, 'json', true);
      }),
      map(
        (data2) => {
          // duplication done
          this.toCreateTimeBoxes.next(null);
          this.toCreateTimeBoxes.next([]);
          return (this.createdTimeBoxes.value);
        }
      ),
      takeLast(1)
    );
  }

  getModuleTimetable(screenId) {
    return this.apiService.get('/api/Timetables/' + screenId + '/Module', 'json', true).pipe(
      map(
        data => {
          this.timetableSubject.next(data);
          this.updateBoxesWithTimetable();
          return (data);
        }
      ));
  }

  getModuleTimetableForOverview(screenId) {
    return this.apiService.get('/api/Timetables/' + screenId + '/Module', 'json', true);
  }

  putTimetable(timeBox) {
    if (this.selectedDates.value.length > 0 && timeBox.timeTableType === 'Special') {
      const dates = this.createdTimeBoxes.value.filter((c) => c.media.mediaId === timeBox.mediaId);
      return from(dates).pipe(
        mergeMap((d) => {
          timeBox.timeTableId = d.timeTableId;
          timeBox.timeTableValue = d.timeTableValue;
          return this.apiService.put('/api/Timetables/', timeBox, 'json', true);
        }),
        map(
          data => {
            // this.setTimetableChangeOn();
            const createdBoxes = this.createdTimeBoxes.value;
            createdBoxes.forEach((c) => {
              if (c.timeTableId === data.timeTableId) {
                c = data;
              }
            });
            this.createdTimeBoxes.next(createdBoxes);
            return (this.createdTimeBoxes.value);
          }
        ),
        takeLast(1)
      );
    }
    return this.apiService.put('/api/Timetables/', timeBox, 'json', true).pipe(
      map(
        data => {
          // this.setTimetableChangeOn();
          this.firebaseService.hasSyncData.next(true);
          return (data);
        }
      ));
  }

  postTimetable(timeBox) {
    if (this.selectedDates.value.length > 0 && timeBox.timeTableType === 'Special') {
      const dates = this.selectedDates.value;
        return from(dates).pipe(
          mergeMap((d) => {
            timeBox.timeTableValue = d.local().format('YYYY-MM-DD');
            return this.apiService.post('/api/Timetables/', timeBox, 'json', true);
          }),
          map(
            data => {
              // this.setTimetableChangeOn();
              const t = this.createdTimeBoxes.value;
              t.push(data);
              this.createdTimeBoxes.next(t);
              return (this.createdTimeBoxes.value);
            }
          ),
          takeLast(1)
        );
    }
    return this.apiService.post('/api/Timetables/', timeBox, 'json', true).pipe(
      map(
        data => {
          // this.setTimetableChangeOn();
          this.firebaseService.hasSyncData.next(true);
          return (data);
        }
      ));
  }

  deleteMultiSpecialFromTimetable(timetable: TimetableEntry[]) {
    return from(timetable).pipe(
      mergeMap((t) => {
        return this.apiService.delete(`/api/Timetables/${t.screenId}/Special/${t.timeTableValue}?timeTableId=${t.timeTableId}`, 'json', true);
      }),
      map(
        data => {
          return (data);
        }
      ),
      takeLast(1)
    );
  }

  deleteSingleSpecialFromTimetable(screenId, timeTableId) {
    if (this.selectedDates.value.length > 0) {
      const origin = this.createdTimeBoxes.value.filter(c => c.timeTableId === timeTableId)[0];
      const toDelete = this.createdTimeBoxes.value.filter(c => c.mediaId === origin.mediaId);
      const dates = this.selectedDates.value;
        return from(toDelete).pipe(
          mergeMap((d) => {

            return this.apiService.delete(`/api/Timetables/${screenId}/Special/${d.timeTableValue}?timeTableId=${d.timeTableId}`, 'json', true);
          }),
          map(
            data => {
              // this.setTimetableChangeOn();
              const t = this.createdTimeBoxes.value;
              t.splice(t.indexOf(data), 1);
              this.createdTimeBoxes.next(t);
              return (this.createdTimeBoxes.value);
            }
          ),
          takeLast(1)
        );
    }
    const date = this.selectedDate.value.format('YYYY-MM-DD');
    return this.apiService.delete(`/api/Timetables/${screenId}/Special/${date}?timeTableId=${timeTableId}`, 'json', true)
      .pipe(
        map(
          data => {
            // this.setTimetableChangeOn();
            return (data);
          }
        )
      );
  }

  deleteModuleFromTimetable(screenId, timeTableId) {
    return this.apiService.delete(`/api/Timetables/${screenId}/Module?timeTableId=${timeTableId}`, 'json', true)
      .pipe(
        map(
          data => {
            // this.setTimetableChangeOn();
            this.firebaseService.hasSyncData.next(true);
            return (data);
          }
        )
      );
  }

  deleteFromTimetable(screenId, timeTableType, timeTableValue, timeTableId) {
    const timeTableValueIsExist = timeTableValue != undefined && !!timeTableValue.toString();

    return this.apiService.delete(
      `/api/Timetables/${screenId}/${timeTableType}${(timeTableValueIsExist ? '/' + timeTableValue : '')}?timeTableId=${timeTableId}`,
      'json',
      true
    ).pipe(
      map(
        data => {
          // this.setTimetableChangeOn();
          this.firebaseService.hasSyncData.next(true);
          return (data);
        }
      )
    );
  }

  changeDay(activeScreenId, index) {
    this.boxes.length = 0;
    this.timeTabIndex.next(index);
    return this.getTimetable(activeScreenId);
  }

  changeLeftMenuTabIndex(index) {
    this.boxes.length = 0;
    this.leftMenuTabIndex.next(index);
  }

  changeDate(activeScreenId, date) {
    this.boxes.length = 0;
    this.selectedDate.next(date);
    return this.getSpecialTimetable(activeScreenId);
  }

  updateBoxesWithTimetable() {
    this.boxes.length = 0; // clear boxes
    const timetable = this.timetableSubject.value;

    if (!timetable) { return; }

    timetable.forEach((timeEntry, i) => {
      const conf: NgGridItemConfig = this._generateDefaultItemConfig();
      const startDate = new Date(timeEntry.startTime);
      const startHour = startDate.getUTCHours();
      const startMinutes = startDate.getUTCMinutes();
      const endDate = new Date(timeEntry.endTime);
      let endHour = endDate.getUTCHours();
      const day = endDate.getUTCDay();
      const endMinutes = endDate.getUTCMinutes();
      if (day === 2 && endHour !== 23) { endHour = 24; }
      const startRow = this.findRowByTime(startHour, startMinutes);
      const sizeY = this.findSizeYByTime(startHour, startMinutes, endHour, endMinutes);

      conf.row = startRow;
      conf.sizey = sizeY;
      conf.payload = i + 1;

      const b = {
        'id': conf.payload,
        'config': conf,
        'media': timeEntry.media,
        startDate,
        endDate,
        startMinutes,
        startHour,
        endHour,
        endMinutes,
        'mediaId': timeEntry.mediaId,
        'screenId': timeEntry.screenId,
        'timeTableId': timeEntry.timeTableId,
        'timeTableType': timeEntry.timeTableType,
        'timeTableValue': timeEntry.timeTableValue,
        'row': startRow,
        'sizey': sizeY,
        'startTime': {
          'minutes': startMinutes,
          'hour': startHour,
          'name': this.buildTimeName(startHour, startMinutes)
        },
        'stopTime': {
          'minutes': endMinutes,
          'hour': endHour,
          'name': this.buildTimeName(endHour, endMinutes)
        },
        '_startTime': timeEntry.startTime,
        '_endTime': timeEntry.endTime
      };
      this.boxes.push(b);
    });
  }

  _generateDefaultItemConfig(): NgGridItemConfig {
    return {'dragHandle': '.handle', 'col': 1, 'row': 1, 'sizex': 1, 'sizey': 1, 'borderSize': 8};
  }

  findRowByTime(hour, minutes) {
    let row = hour * 2 + 1;
    if (minutes > 0) {
      row += 1;
    }
    return row;
  }

  findSizeYByTime(startHour, startMinutes, endHour, endMinutes) {
    return ((endHour * 2) + (endMinutes > 0 ? 1 : 0)) - ((startHour * 2) + (startMinutes > 0 ? 1 : 0));
  }

  buildTimeName(hour, minutes) {
    return (hour < 10 ? '0' : '') + hour + ':' + (minutes > 0 ? minutes : '00');
  }

  // Returns true if the selected time collides with any other times on the timetable
  colisionCheck(startTime, stopTime, referenceTimeTableId) {
    let isBlocked = false;
    const timetable = this.timetableSubject.value;
    const blockedTimes = this.buildBlockedTimes(timetable, referenceTimeTableId);
    const newStart = startTime.minutes > 0 ? (startTime.hour + 0.5) : startTime.hour;
    const newStop = stopTime.minutes > 0 ? (stopTime.hour + 0.5) : stopTime.hour;
    const leng = blockedTimes.length;
    for (let i = 0; i < leng; i++) {
      let sm, sh, em, eh;
      sm = blockedTimes[i].startMinutes;
      sh = blockedTimes[i].startHour;
      em = blockedTimes[i].endMinutes;
      eh = blockedTimes[i].endHour;
      let start, stop;
      start = sm > 0 ? (sh + 0.5) : sh;
      stop = em > 0 ? (eh + 0.5) : eh;
      // check if new time overlap with exiting timeframes
      if ((newStart >= start && newStart < stop) || (newStop > start && newStop <= stop || newStart <= start && newStop >= stop)) {
        // This time is blocked
        isBlocked = true;
        break;
      } // else not blocked
    }
    return isBlocked;
  }

  // returns array of blocked times of timetable, excludes own id
  buildBlockedTimes(timetable, referenceTimeTableId) {
    const blockedTimes = [];
    if (timetable) {
      const timeTableCount = timetable.length;
      for (let i = 0; i < timeTableCount; i++) {
        const startDate = new Date(timetable[i].startTime);
        const startHour = startDate.getUTCHours();
        const startMinutes = startDate.getUTCMinutes();
        const endDate = new Date(timetable[i].endTime);
        let endHour = endDate.getUTCHours();
        const day = endDate.getUTCDay();
        if (day === 2) { endHour = 24; }
        const endMinutes = endDate.getUTCMinutes();
        const b = {
          startHour: startHour,
          startMinutes: startMinutes,
          endHour: endHour,
          endMinutes: endMinutes
        };
        if (referenceTimeTableId === timetable[i].timeTableId) {
          // exclude own id (dont push)
        } else {
          blockedTimes.push(b);
        }
      }
    }
    return blockedTimes;
  }

  setTimetableChangeOn() {
    let firebaseAuthKey = null;
    if (this.companiesService.activeFirebaseScreen.value) {
      firebaseAuthKey = this.companiesService.activeFirebaseScreen.value.data.authKey;
    }
    const authKey = this.activeScreen.value.authKey;

    if (authKey && firebaseAuthKey !== authKey) {
      this.companiesService.getScreenByAuthKey(authKey)
        .subscribe(data => {
          if (data) {
            if (data.id) {
              // outcommented due to performance test (sync only on sync button click)
              // this.firebaseService.updateScreenTimeTableStamp(data.id);
            }
          }
        });
    }

    if (firebaseAuthKey === authKey) {
      // see above comment
      // this.firebaseService.updateScreenTimeTableStamp(this.companiesService.activeFirebaseScreen.value.id);
    }
  }
}
