import { Pipe, PipeTransform } from '@angular/core';

import { Media } from '../models/media';

@Pipe({
    name: 'mediaFilter',
    pure: false
})
export class MediaFilterPipe implements PipeTransform {
  transform(items: Media[], filter: Media): Media[] {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: Media) => this.applyFilter(item, filter));
  }

  /**
   * Perform the filtering.
   *
   * @param {Book} book The book to compare to the filter.
   * @param {Book} filter The filter to apply.
   * @return {boolean} True if book satisfies filters, false if not.
   */
  applyFilter(media: Media, filter: Media): boolean {
    for (let field in filter) {
      if (filter[field]) {
        if (typeof filter[field] === 'string' && media[field]) {
          if (media[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
            return false;
          }
        } else if (typeof filter[field] === 'number') {
          if (media[field] !== filter[field] && media[field]) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
