import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatIconRegistry, MatSnackBar } from '@angular/material';

@Component({
    selector: 'update-prompt-dialog',
    templateUrl: 'update-prompt-dialog.html',
})
export class UpdatePromptDialog implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<UpdatePromptDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    onConfirm() {
        this.data.overwrite = true;
        this.dialogRef.close(this.data);
    }

}