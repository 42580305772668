import { Component, OnInit, Inject, NgModule, Type } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MediaService, TimetableService } from '../shared';
import * as moment from 'moment';
import { TimetableEntry } from '../shared/models/timetable.model';

@Component({
  selector: 'special-date-dialog',
  templateUrl: 'special-date-dialog.html',
})
export class SpecialDateDialog implements OnInit {

  loading = {
    usedDates: true,
    duplicating: false
  };
  usedDates = [];
  datepickerConf = {};
  selectedDate = undefined;
  minDate = moment();
  deleting = null;

  constructor(
    public dialogRef: MatDialogRef<SpecialDateDialog>,
    public mediaService: MediaService,
    private timetableService: TimetableService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.data.duplicate = null;
    if (this.data.viewMode) {
      this.usedDates = this.data.usedDates;
      this.loading.usedDates = false;
      this.setDatePickerConfig(this.usedDates);
    } else {
      this.getUsedDates();
    }
  }

  getUsedDates() {
    this.loading.usedDates = true;
    this.mediaService.getUsedDates(this.data.activeScreenId)
      .subscribe(
        data => {
          const future = [];
          data.dates.forEach((d) => {
            if (moment(d).isAfter(moment().subtract(1, 'days'))) {
              future.push(d);
            }
          })
          this.usedDates = future;
          this.setDatePickerConfig(this.usedDates);
          this.loading.usedDates = false;
        },
        err => {
          this.loading.usedDates = false;
        }
      );
  }

  setDatePickerConfig(dates) {
    this.datepickerConf = {
      dayBtnCssClassCallback: function (day) {
        let cssClass;
        for (let i = 0; i < dates.length; i++) {
          if (day.format('YYYY-MM-DD') === dates[i]) {
            cssClass = 'has-entry';
            break;
          }
        }
        return cssClass;
      },
      min: moment(),
      allowMultiSelect: true,
      locale: 'de-ch'
    };
  }

  onDeleteDate(date, event) {
    event.stopPropagation();
    event.preventDefault();
    this.timetableService.duplicate.next(null);
    if (window.confirm('Sind Sie sicher, dass Sie alle Timetable Einträge für das Spezialdatum ' + date + ' löschen möchten?')) {
      this.deleting = date;
      // first we get all timetableentries for this date
      this.timetableService.getSpecialTimetable(this.data.activeScreenId, date).subscribe((res) => {
        console.log('Timetable Entries goten for deletion', res);
        this.timetableService.deleteMultiSpecialFromTimetable(res).subscribe(() => {
          console.log('ALL ENTRIES DELETED');
          this.usedDates.splice(this.usedDates.indexOf(date), 1);
          this.snackBar.open('Spezialdatums-Timetableeinträge erfolgreich gelöscht.', null, {
            duration: 2000
          });
        });
      });
    }
  }

  onDuplicateDate(date, event) {
    event.stopPropagation();
    event.preventDefault();
    this.timetableService.duplicate.next(date);
    this.data.duplicate = true;
    this.data.viewMode = false;
  }

  onSelectDate(date) {
    this.usedDates.forEach(u => {
      date.forEach(d => {
        if (d.local().format('YYYY-MM-DD') === u) {
          this.data.selectedDate = u;
          this.timetableService.selectedDate.next(d);
          this.dialogRef.close(this.data);
        }
      });
    });
  }

  onSelectUsedDate(date) {
    this.data.selectedDate = date;
    this.dialogRef.close(this.data);
  }

  onSaveSelectedDates() {
    if (this.data.duplicate) {
      return;
    } else {
      this.data.selectedDate = null;
      this.data.selectedDates = this.selectedDate;
    }
    this.timetableService.selectedDates.next(this.selectedDate);
    this.timetableService.createdTimeBoxes.next(null);
    this.timetableService.createdTimeBoxes.next([]);
    this.dialogRef.close(this.data);
  }

  onDuplicateSelectedDates() {
    if (this.data.duplicate) {
      this.data.selectedDate = this.timetableService.duplicate.value;
      this.data.selectedDates = [];
    } else {
      return;
    }
    this.timetableService.selectedDates.next(this.selectedDate);
    this.timetableService.createdTimeBoxes.next(null);
    this.timetableService.createdTimeBoxes.next([]);
    // Duplication Handling
    const date = this.timetableService.duplicate.value;
    if (date) {
      this.loading.duplicating = true;
      // get special timetable
      this.timetableService.getSpecialTimetable(this.data.activeScreenId, date).subscribe(
        (res) => {
          // this is the data we want to duplicate onto all selected dates
          this.makeToCreateSpecialTimeBoxes(res);
          const toCreateTimeBoxes = this.timetableService.toCreateTimeBoxes.value;
          this.timetableService.duplicateSpecialTimetable().subscribe(
            (res) => {
              console.log('duplication has finished', res);
              this.getUsedDates();
              this.loading.duplicating = false;
              this.snackBar.open(toCreateTimeBoxes.length + ' Timetable Einträge wurden erstellt');
              this.data.viewMode = true;
            }
          );
        }
      );
    }
  }

  makeToCreateSpecialTimeBoxes(originalBoxes: TimetableEntry[]) {
    console.log('makeToCreateSpecialTimeBoxes this data', originalBoxes);
    const dates = this.timetableService.selectedDates.value;
    console.log(dates);
    // boxes are special timetable entries we want to duplicate to other dates (selectedDates)
    const boxes = originalBoxes;
    const toCreate = [];
    // each of the selected date gets each of the boxes
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const d = date.local().format('YYYY-MM-DD');
      console.log('date: ', d);
      for (let j = 0; j < boxes.length; j++) {
        const box: TimetableEntry = {
          mediaId: boxes[j].mediaId,
          screenId: boxes[j].screenId,
          timeTableType: 'special',
          timeTableValue: d,
          startTime: boxes[j].startTime,
          endTime: boxes[j].endTime
        };
        boxes[j].timeTableId = null;
        boxes[j].timeTableValue = null;
        boxes[j].timeTableValue = d;
        console.log('push box: ', box);
        toCreate.push(box);
      }
    }

    this.timetableService.toCreateTimeBoxes.next(toCreate);
    console.log('toCreate', toCreate);
  }

  onConfirm() {
    this.data.overwrite = true;
    this.dialogRef.close(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  hasMedia(day) {
    return 'test-classeee';
  }

  getViewDate(date) {
    return moment(date).local().format('dddd, D. MMMM YYYY');
  }

}
