import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MediaItem } from '../shared/types/media-item.type';
import { environment } from '../../environments/environment';
import { timer, interval } from 'rxjs';
import { AnimationBuilder, style, animate, AnimationPlayer } from '@angular/animations';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnDestroy, AfterViewInit {
  sliderInterval: any;
  sliderIndex = 0;
  nextSliderIndex = 0;
  state = 'in';
  azureCDN = environment.azureCDN;
  videoMediaItems: MediaItem[];
  activeSlide = null;
  showNextSlide = false;
  private animationPlayer: AnimationPlayer;
  position = 0;
  endSoonSent = false;

  @Input() mediaItems: MediaItem[];
  @Input() startMedia: MediaItem;
  @Input() speed: number;
  @Input() useLocalSrc: boolean;
  @Input() animationDuration: number;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @ViewChild('slider', { static: false }) slider: ElementRef;

  constructor(
    private animationBuilder: AnimationBuilder
  ) { }

  ngOnInit() {
    const startIndex = this.mediaItems.findIndex(item => item.mediaItemId === this.startMedia.mediaItemId);
    this.sliderIndex = startIndex > 0 ? startIndex : 0;
    this.startSlider(this.mediaItems);
  }

  ngOnDestroy() {
    this.stopSlider();
  }

  ngAfterViewInit() {
    this.makeSliderAnimation(true);
  }

  getNextSliderIndex() {
    let idx = 0;
    if (this.mediaItems[this.sliderIndex + 1]) {
      idx = this.sliderIndex + 1;
    } else {
      idx = 0;
    }
    return idx;
  }

  startSlider(mediaItems: MediaItem[], origin?) {
    this.stopSlider();
    const fileExt = this.returnFileExt(mediaItems[this.sliderIndex].filename);
    if (fileExt === 'mp4') {
      // pause slider
      this.makeVideoItems(mediaItems);
      this.stopSlider();
      return;
    }
    let o = origin;
    if (o === 'video') {
      // immediately show slide 
      this.proceedSlide(mediaItems, o);
    }
    const interval1 = interval(mediaItems[0].showSeconds * 1000);
    this.sliderInterval = interval1.subscribe((val) => {
      this.proceedSlide(mediaItems)

    });
  }

  proceedSlide(mediaItems, o?) {
    if (this.returnFileExt(mediaItems[this.sliderIndex].filename) === 'mp4') {
      // pause slider
      this.makeVideoItems(mediaItems);
      this.stopSlider();
      return;
    }
    if (mediaItems.length - 1 === 0) {
      this.stopSlider();
      this.sliderIndex = 0;

      return;
    }
    if (o !== 'video') {
      if (this.sliderIndex < mediaItems.length - 1) {
        this.sliderIndex += 1;
      } else {
        this.sliderIndex = 0;
      }
    } else {
      o = null;
    }
    if (this.returnFileExt(this.mediaItems[this.sliderIndex].filename) === 'mp4') {
      // if next file slide is video we will trigger out animation at video end
      // pause slider
      this.stopSlider();
      this.makeVideoItems(mediaItems);
    } else {
      this.setSlideEndAnimation();
    }
  }

  setSlideEndAnimation(next?) {
    const timer2 = timer(this.mediaItems[0].showSeconds * 1000 - this.animationDuration); // apply out animation 300 ms before slide end
    const timer2$ = timer2.subscribe((val) => {
      this.makeSliderAnimation(null, 'next');
    });
  }


  makeSliderAnimation(init?, type?) {
    if (this.animationPlayer) {
      this.animationPlayer.destroy();
    }
    // slide leave animation
    let direction = 1;
    if (this.sliderIndex < this.getNextSliderIndex() || this.getNextSliderIndex() === 0) {
      direction = -1;
    }

    const l = (100 / this.mediaItems.length) * (this.sliderIndex - 1) >= 0 ? (100 / this.mediaItems.length) * (this.sliderIndex - 1) : 0;
    const a = (100 / this.mediaItems.length) * this.sliderIndex;
    let p = (100 / this.mediaItems.length) * (type === 'next' ? this.getNextSliderIndex() : this.sliderIndex) * direction;
    if (init && a === 0) {
      p = 0;
    }

    // animation factory
    let factory;

    if (init && a === 0) {
      factory = this.animationBuilder.build([
        style({ width: this.mediaItems.length * 100 + '%', transform: 'translateX(' + (a + 50).toString() + '%)' }),
        animate(this.animationDuration + 'ms cubic-bezier(.35, 0, .25, 1)', style({ transform: 'translateX(' + a.toString() + '%)' }))
      ]);
    } else {
      factory = this.animationBuilder.build([
        style({ width: this.mediaItems.length * 100 + '%', transform: 'translateX(' + this.position + '%)' }),
        animate(this.animationDuration + 'ms cubic-bezier(.35, 0, .25, 1)', style({ transform: 'translateX(' + p.toString() + '%)' }))
      ]);
    }

    this.animationPlayer = factory.create(this.slider.nativeElement);
    this.animationPlayer.play();

    this.position = p;

    if (init && this.returnFileExt(this.mediaItems[this.sliderIndex].filename) !== 'mp4') {
      this.setSlideEndAnimation('next');
    }
  }

  makeVideoItems(mediaItems) {
    this.endSoonSent = false;
    const videoItems = [];
    this.videoMediaItems = null;
    videoItems.push(mediaItems[this.sliderIndex]);
    setTimeout(() => {
      this.videoMediaItems = videoItems;
    }, 0);
  }

  stopSlider() {
    if (this.sliderInterval) {
      this.sliderInterval.unsubscribe();
    }
  }

  getCDNUrl(publicUrl) {
    // Why? because we dont have the companyId: https:/....net/[[companyId]]/[[fileId]]
    return publicUrl.replace('https://iascreenmanager.blob.core.windows.net/', environment.azureCDN);
  }

  returnFileExt(filename: string) {
    // get file extension
    const splitted = filename.split('.');
    return splitted[splitted.length - 1].toLowerCase();
  }

  onPlayerEndSoon(event) {
    if (!this.endSoonSent) {
      this.endSoonSent = true;
      this.makeSliderAnimation(null, 'next');
    }
  }

  onPlayerEnded() {
    this.videoMediaItems = null;
    // skip video slide
    this.sliderIndex = this.getNextSliderIndex()
    const mediaItems = this.mediaItems;
    const fileExt = this.returnFileExt(mediaItems[this.sliderIndex].filename);
    if (fileExt === 'mp4') {
      this.makeVideoItems(mediaItems);
    } else {
      this.startSlider(this.mediaItems, 'video');
    }
  }

  close() {
    this.onClose.emit();
  }

}
