import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { CompaniesService } from '../shared';

@Component({
  selector: 'company-dialog',
  templateUrl: 'company-dialog.html',
})
export class CompanyDialog implements OnInit {

  saving = false;

  constructor(
    public dialogRef: MatDialogRef<CompanyDialog>,
    public companiesService: CompaniesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (!this.data.company.quota) {
      this.data.company.quota = 5000.00;
    }
  }

  onConfirm() {
    this.dialogRef.close(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    if (!this.data.company.companyName) { return; }
    if (this.data.company.companyId) {
      this.updateCompany();
    } else {
      this.createCompany();
    }
  }

  createCompany() {
    this.saving = true;
    const company = {
      'companyName': this.data.company.companyName,
      'isBlocked': this.data.company.isBlocked,
      'deleteDate': null,
      'quota': this.data.company.quota
    };
    this.companiesService.createCompany(company)
    .subscribe(
      data => {
        this.dialogRef.close(this.data);
      },
      err => {
        this.saving = false;
      }
    );
  }

  updateCompany() {
    this.saving = true;
    const company = {
      'companyId': this.data.company.companyId,
      'companyName': this.data.company.companyName,
      'isBlocked': this.data.company.isBlocked,
      'deleteDate': null,
      'quota': this.data.company.quota
    };
    this.companiesService.updateCompany(company)
    .subscribe(
      data => {
        this.dialogRef.close(this.data);
      },
      err => {
        this.saving = false;
      }
    );
  }

}
