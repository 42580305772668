import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import { UserService } from './user.service';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  private urlExclusions: string[] = [
    'Token',
    'Screens/0',
  ];

  constructor(private toastr: ToastrService, private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.hasExclusions(req, this.urlExclusions)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error.status === 401) {
          // Unauthorized: Logout (TODO: Refresh, F.B.A.V.A.)
          this.toastr.error(`Session abgelaufen, bitte erneut authentifizieren. Fehlercode: ${error.status}`);
          this.userService.logout();
        } else {
          this.toastr.error(`Ein Fehler ist aufgetreten. Fehlercode: ${error.status}`);
        }

        if (!environment.production) {
          console.group('Http error');
          console.groupEnd();
        }

        return throwError(error);
      })
    );
  }

  private hasExclusions(req: HttpRequest<any>, exclusions: string[]): boolean {
    for (const exclusion of exclusions) {
      if (req.url.indexOf(exclusion) >= 0) {
        return true;
      }
    }

    return false;
  }
}
