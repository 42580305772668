
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Company, AllCompanies } from '../models';
import { FireFunctionsApiService } from './firefunctions.api.service';

@Injectable()
export class CompaniesService {
  public allCompanies = new BehaviorSubject<Company[]>([]);
  public company = new BehaviorSubject<Company>(null); // use this
  activeFirebaseScreen = new BehaviorSubject(null);
  public companyId = new BehaviorSubject<string>(null); // deprecated, use company.companyId

  constructor(
    private apiService: ApiService,
    private fireFunctionApi: FireFunctionsApiService
  ) { }

  changeCompany(company) {
    if (JSON.stringify(company) == JSON.stringify(this.company.value)) { return }
    this.company.next(company);
  }

  getActiveCompany() {
    return this.company;
  }

  getAllCompanies() {
    return this.apiService.get('/api/Companies', 'json', true).pipe(
    map(
      (data: AllCompanies) => {
        this.allCompanies.next(data.result);
        return(data);
      }
    ));
  }

  updateCompany(company) {
    return this.apiService.put('/api/Companies/', company, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

  createCompany(company) {
    return this.apiService.post('/api/Companies/', company, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

  updateScreen(screen) {
    return this.apiService.put('/api/Screens/', screen, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

  createScreen(screen) {
    return this.apiService.post('/api/Screens/', screen, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

  updateLocation(location) {
    return this.apiService.put('/api/Locations/', location, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

  resetScreen(screenId) {
    return this.apiService.put('/api/Screens/Reset/' + screenId, {}, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

  getScreenById(screenId) {
    return this.apiService.get('/api/Screens/' + screenId, 'json', true).pipe(map(
      data => {
        return(data);
      }
    ));
  }

  getScreenByAuthKey(authKey) {
    return this.fireFunctionApi.get('screens/'+authKey, 'json', false).pipe(
      map(
        data => {
          this.activeFirebaseScreen.next(data);
          return(data);
        }
      )
    );
  }

  createLocation(location) {
    return this.apiService.post('/api/Locations/', location, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }
}
