export class AllCompanies {
  count: number;
  next?: any;
  prev?: any;
  result: Company[];
}

export class Company {
  companyId: string;
  modified: string;
  created: string;
  currentSize?: number;
  isBlocked: boolean;
  deleteDate?: string;
  companyName: string;
  quota: number;
}