import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FirebaseService } from '../../shared';
import { FavoriteMediasFolder } from '../../shared/models/favoriteMediasFolder.model';

@Component({
  selector: 'app-favorite-medias-folders-dialog',
  templateUrl: './favorite-medias-folders-dialog.component.html',
  styleUrls: ['./favorite-medias-folders-dialog.component.scss']
})
export class FavoriteMediasFoldersDialogComponent implements OnInit {

  folder: FavoriteMediasFolder = new FavoriteMediasFolder();
  loading = false;
  error = null;

  constructor(
    private dialogRef: MatDialogRef<FavoriteMediasFoldersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar
  ) {
    if (data.folder) {
      this.folder = data.folder;
    }
   }

  ngOnInit() {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.error = null;
    this.loading = true;
    // create the folder in firestore
    this.firebaseService.createFavoriteMediaFolder(this.folder).subscribe(
      (res) => {
        this.snackBar.open("Neuer Favoriten Ordner '"+this.folder.folderName+"' wurde erstellt.", null, {duration: 2500});
        this.dialogRef.close(res);
        this.loading = false;
      },
      (err) => {
        this.error = err;
        this.loading = false;
      }
    )

  }

}
