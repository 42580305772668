export class Media {
  mediaId: string;
  mediaItems: MediaItem[];
  mediaTypeName: string;
  multipleUsed: boolean;
  multipleCount: number;
  customType: boolean;
  mediaDescription: string;
  mediaTypeId: string;
  mediaItemSlideType?: any;
  isFavourite: boolean;
  companyId: string;
}

export class MediaItem {
  mediaItemId: string;
  publicUrl?: any;
  mediaId: string;
  config: string;
  showSeconds?: any;
  filename?: any;
  companyId: string;
  sortNr?: any;
  favoriteMediasFoldersId?: string;
}

export class MediaResult {
  mediaId: string;
  mediaItems: MediaItem[];
  mediaTypeName: string;
  multipleUsed: boolean;
  multipleCount: number;
  customType: boolean;
  mediaDescription: string;
  mediaTypeId: string;
  mediaItemSlideType?: any;
  isFavourite: boolean;
  companyId: string;
}

export class MediaSearchResponse {
  count: number;
  next?: any;
  prev?: any;
  result: MediaResult[];
}


