import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'confirm-delete-dialog',
    templateUrl: 'confirm-delete-dialog.html',
})
export class ConfirmDeleteDialog implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteDialog>
    ) {
    }

    ngOnInit() {
    }

    onNo() {
        this.dialogRef.close(false);
    }

    onConfirm() {
        this.dialogRef.close(true);
    }

}