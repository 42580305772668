
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FFMPEGService {
    public bearerToken: string;
    public currentUser: any;

    constructor(
        private http: HttpClient,
    ) { }

    setHeaders(headerType, authenticate): HttpHeaders {
        let headersConfig: { [k: string]: any } = {};
        if (headerType === 'json') {
            headersConfig = {
                'Content-Type': 'application/json'
            };
        } else if (headerType === 'form') {
            headersConfig = {
                'Content-Type': 'application/x-www-form-urlencoded'
            };
        } else if (headerType === 'multipart') {
            headersConfig = {
                // 'Content-Type': 'multipart/form-data' (not needed in Angular 4)
            };
        }
        if (authenticate) {
            if (localStorage.getItem('currentUser')) {
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                headersConfig['authorization'] = 'bearer ' + this.currentUser.access_token;
            }

        }

        return new HttpHeaders(headersConfig);
    }

    formatErrors(error: any) {
        return observableThrowError(error.json());
    }

    post(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
        return this.http.post(environment.ffmpeg_url + path, body, { headers: this.setHeaders(headerType, authenticate) });
    }

    get(path: string, headerType: string, authenticate: boolean): Observable<any> {
        return this.http.get(environment.ffmpeg_url + path, { headers: this.setHeaders(headerType, authenticate) });
    }

    put(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
        return this.http.put(environment.ffmpeg_url + path, body, { headers: this.setHeaders(headerType, authenticate) });
    }

    toggle(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
        return this.http.put(environment.ffmpeg_url + path, body, { headers: this.setHeaders(headerType, authenticate) });
    }

    delete(path: string, headerType: string, authenticate: boolean): Observable<any> {
        return this.http.delete(environment.ffmpeg_url + path, { headers: this.setHeaders(headerType, authenticate) });
    }

    upload(path: string, file, headerType: string, authenticate: boolean): Observable<any> {
        return this.http.post(environment.ffmpeg_url + path, file, { headers: this.setHeaders(headerType, authenticate) });
    }

    uploadWithProgress(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
        const headers = this.setHeaders(headerType, authenticate);
        return this.http.post(environment.ffmpeg_url + path, body, { headers: this.setHeaders(headerType, authenticate), reportProgress: true, observe: 'events' });
    }
}
