import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { VgAPI } from 'videogular2/compiled/core';
import { MediaItem } from '../shared';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

  video: HTMLVideoElement;
  azureCDN = environment.azureCDN;
  api: VgAPI;
  api$: any;
  api2$: any;
  api3$: any;
  env = environment.identifier;


  @Input() mediaItems: MediaItem[];
  @Input() useLocalSrc: boolean;
  @Input() loop: boolean;
  @Input() startAnimationDuration: number;
  @Input() endAnimationDuration: number;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPlayerEnded: EventEmitter<any> = new EventEmitter();
  @Output() onPlayerEndSoon: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.api$.unsubscribe();
    //this.api2$.unsubscribe();
    this.api3$.unsubscribe();
  }

  onPlayerReady(api: VgAPI) {
    this.api = api;

    // watch for video ended event
    this.api$ = this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // emit ended event
        this.onPlayerEnded.emit(true);
      }
    );     
    // watch for timeupdate change - 230ms event (for leave animation)
    this.api3$ = this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(
      (d) => {
        //console.log("time update change", d)

        const current =  d.srcElement.currentTime * 1000;
        const duration = d.srcElement.duration * 1000;
        const buffer = 0;
        // check if 230ms before ended event (for leave animation), include 500ms extra buffer because lag
        if (duration - this.endAnimationDuration - buffer  <= current) {
        // emit end soon event
        console.log("player end soon")
        this.onPlayerEndSoon.emit(true);
        }
      }
    );
  }

  canPlay(event) { }

  getCDNUrl(publicUrl) {
    // Why? because we dont have the companyId: https:/....net/[[companyId]]/[[fileId]]
    return publicUrl.replace('https://iascreenmanager.blob.core.windows.net/', environment.azureCDN);
  }

}
