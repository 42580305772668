
import {filter} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatIconRegistry, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router, Params, NavigationStart } from '@angular/router';
import { LocationDialogComponent } from '../dialogs/location-dialog.component';
import { ScreenDialog } from '../dialogs/screen-dialog.component';
import { CompaniesService, LocationsService, TimetableService, FirebaseService } from '../shared';
import * as _ from 'lodash';

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['../timeline/timeline.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy {

  @Input() activeScreenId;
  @Output('changeScreen')
    change: EventEmitter<string> = new EventEmitter<string>();
  @Output('addScreen')
    addScreen: EventEmitter<string> = new EventEmitter<string>();
  @Output('openScreenDialog')
    screen: EventEmitter<string> = new EventEmitter<string>();
  @Output('openLocationDialog')
    location: EventEmitter<string> = new EventEmitter<string>();

    activeLocation: any;
    allLocations = [];
    currentRoute: any;
    selectedScreenId: any;
    selectedAuthKey: any;
    toSelectScreen: any;

    // Url Params
    locId: any; // locationId

    // Subscriptions
    routerEventsSubscription: any;

  constructor(
    public dialog: MatDialog,
    public companiesService: CompaniesService,
    public locationsService: LocationsService,
    public router: Router,
    private route: ActivatedRoute,
    private timetableService: TimetableService,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar
  ) {
    // Initialize & Subscribe to active Location
    this.activeLocation = this.locationsService.activeLocation;
    this.locationsService.activeLocation.subscribe(
      value => {
        this.activeLocation = value;
        // reset sync button state
        this.firebaseService.hasSyncData.next(false);
      }
    );
    // Subscribe to List of all Locations
    this.locationsService.allLocations.subscribe(
      value =>  {
        this.allLocations = value;
      }
    );
  }


  ngOnInit() {
    this.handleUUIDConnectScreen();
    const href = window.location.href.split('/');
    this.currentRoute = '/' + href[href.length - 2] + '/' + href[href.length - 1].split('?')[0];
    this.routerEventsSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event.url) {
          this.currentRoute = event.url.split('?')[0]; // remove url params
        }
      });

    // Get URL Parameters for initialization purposes
    const locId = this.route.snapshot.paramMap.get("locationId");

      if (locId) {
        // Initialize the location based on url param locId
        this.allLocations.forEach((location, index) => {
          if (location.locationId === locId) {
            // this.toggleLocation(location, index);
            const loc = _.cloneDeep(location);
            this.locationsService.setActiveLocation(loc, index);
          }
        });
      } else {
        // Initialize the first location from allLocations, which has screens
        // only if overview is active
          const url = 'overview/' + this.companiesService.company.value.companyId + '/' + this.allLocations[0].locationId;
          this.router.navigateByUrl(url);
          this.toggleLocation(this.allLocations[0], 0);
      }
  }

  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe();
  }

  handleUUIDConnectScreen() {
    const c = localStorage.getItem('connectScreen');
    if (c) {
      this.openScreenDialog({}, null, null);
    }
    const uuid = localStorage.getItem('uuid');
    if (uuid) {
      localStorage.removeItem('uuid');
      // check if uuid present on firestore
      this.firebaseService.readScreens(uuid)
        .subscribe(
          fireScreen => {
            const f = fireScreen.fields;
            if (f.companyId && f.locationId && f.screenId) {
              // is a connected screen, redirect to correct timetable now
              // tslint:disable-next-line:max-line-length
              this.snackBar.open("Screen existiert bereits. Gehe zur Screen " + uuid + " Timeline")
              this.router.navigateByUrl('timeline/' + f.companyId.stringValue + '/' + f.locationId.stringValue + '/' + f.screenId.stringValue);
            } else {
              // is a new screen, redirect to timeline and open connect dialog
              localStorage.setItem('connectScreen', JSON.stringify(fireScreen));
                this.openScreenDialog({}, null, null);
            }
          },
          err => {
            if (err) {
              // screen not found in firestore TODO SHOW Error
              this.snackBar.open('ERROR: Screen nicht gefunden!', undefined, {
                duration: 3000,
                horizontalPosition: 'start'
              });
            }
          }
        );
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  preventDefault(event) {
    event.stopPropagation();
  }

  toggleLocation(location, index) {
    const loc = _.cloneDeep(location);
    this.locationsService.setActiveLocation(loc, index);
    // tslint:disable-next-line:max-line-length
    this.router.navigateByUrl('overview/' + this.companiesService.company.value.companyId + '/' + location.locationId);
  }

  onChangeScreen(screenId) {
    this.selectedScreenId = screenId;
    this.change.emit(screenId);
  }

  onAddScreen(screen) {
    if (screen) {
      if (screen.screenId) {
        this.toSelectScreen = screen;
      }
      if (screen.screen) {
        this.toSelectScreen = screen.screen;
      }
    }

    this.getAllScreens();
  }

  getAllScreens() {
    this.locationsService.getAllLocationsWithScreens(this.companiesService.company.value.companyId).subscribe(
      result => {
        if (this.toSelectScreen) {
            const set = this.toSelectScreen;
            this.toSelectScreen = null;
            this.onChangeScreen(set.screenId);
        }
      }
    );
  }

  albhabeticallySorted(screens) {
   return screens.sort((val1, val2) => {
      if (val1.screenName.toLowerCase() < val2.screenName.toLowerCase()) { return -1; }
      if (val1.screenName.toLowerCase() > val2.screenName.toLowerCase()) { return 1; }
      return 0;
    });
  }

  openScreenDialog(screen, locationId, event): void {
    let isNewScreen = false;
    if (!screen.screenId) {
      isNewScreen = true;
    }
    // event.stopPropagation();
    screen.locationId = locationId;
    const dialogRef = this.dialog.open(ScreenDialog, {
      width: '420px',
      disableClose: false,
      data: {
        screen: screen
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (isNewScreen) {
        this.onAddScreen(result);
      } else {
        this.getAllScreens();
      }
    },
      err => {
        // this.getAllLocations(this.company.companyId);
      });
  }

  openLocationDialog(location): void {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      width: '420px',
      disableClose: false,
      data: {
        location: location,
        company: this.companiesService.company.value
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // nil
      console.log("closed locatiion dialog", result)
    });
  }
}
