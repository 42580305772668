import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../shared';

@Injectable()
export class AuthGuard implements CanActivate {


    constructor(private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const c = localStorage.getItem('currentUser');
        const currentUser: User = JSON.parse(c);
        if (currentUser && currentUser.access_token && currentUser.isSysAdmin) {
            this._router.navigateByUrl('dashboard');
            return false;
        }
        if (currentUser && currentUser.access_token && currentUser.companyId) {
            this._router.navigateByUrl('overview/' + currentUser.companyId);
            return false;
        }

        return true;

    }

}
