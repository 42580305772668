import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompaniesService, LocationsService } from '../shared';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
    selector: 'location-dialog',
    templateUrl: 'location-dialog.html',
})
export class LocationDialogComponent implements OnInit {

    saving = false;

    constructor(
        public dialogRef: MatDialogRef<LocationDialogComponent>,
        public companiesService: CompaniesService,
        public locationsService: LocationsService,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        // nil
    }

    onConfirm() {
        this.dialogRef.close(this.data);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSave() {
        if (!this.data.location.locationName) { return; }
        if (this.data.location.locationId) {
            this.updateLocation();
        } else {
            this.createLocation();
        }
    }

    createLocation() {
        this.saving = true;
        const location = {
            'locationName': this.data.location.locationName,
            'companyId': this.data.company.companyId,
            'isBlocked': this.data.location.isBlocked ? true : false
        };
        this.companiesService.createLocation(location)
            .subscribe(
                data => {
                    const company = this.companiesService.company.value;
                    this.locationsService.getAllLocationsWithScreens(company.companyId).subscribe(
                        locations => {
                            this.router.navigateByUrl('overview/' + company.companyId + '/' + data.locationId);
                        }
                    );
                    this.dialogRef.close(this.data);
                },
                err => {
                    this.saving = false;
                }
            );
    }

    updateLocation() {
        this.saving = true;
        const location = {
            'companyId': this.data.location.companyId,
            'locationId': this.data.location.locationId,
            'locationName': this.data.location.locationName,
            'isBlocked': this.data.location.isBlocked,
            'deleteDate': null
        };
        this.companiesService.updateLocation(location)
            .subscribe(
                data => {
                    const company = this.companiesService.company.value;
                    this.locationsService.getAllLocationsWithScreens(company.companyId).subscribe(
                        locations => {
                            this.router.navigateByUrl('overview/' + company.companyId + '/' + data.locationId);
                        }
                    );
                    this.dialogRef.close(this.data);
                },
                err => {
                    this.saving = false;
                }
            );
    }

}
