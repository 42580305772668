
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { ApiService } from './api.service';
import { Location } from '../models/location.model';

@Injectable()
export class LocationsService {
  public allLocationsSubject = new BehaviorSubject([]);
  public allLocations = this.allLocationsSubject.asObservable().pipe(distinctUntilChanged());
  private _allLocationsSubject = new BehaviorSubject([]);
  private _allLocations = this.allLocationsSubject.asObservable().pipe(distinctUntilChanged());
  private companyId = new BehaviorSubject(null);

  activeLocation = new BehaviorSubject<Location>(null);
  activeLocationIndex = new BehaviorSubject(0);



  constructor(
    private apiService: ApiService
  ) { }

  getAllLocations() {
    return this.apiService.get('/api/Locations', 'json', true).pipe(
    map(
      data => {
        this.allLocationsSubject.next(data.result);
      }
    ));
  }

  getAllLocationsWithScreens(companyId) {
    return this.apiService.get('/api/Locations/Screens?companyId=' + companyId, 'json', true).pipe(
    map(
      (data: Location[]) => {
        if (!data) {
          this.allLocationsSubject.next([]);
          return null;
        } else {
          data.forEach((location) => {
            location.screens = this.alphabeticallySorted(location.screens);
          })
          if (!(_.isEqual(this._allLocationsSubject.value, data))) {
            this.allLocationsSubject.next(_.cloneDeep(data));
            this._allLocationsSubject.next(_.cloneDeep(data));
          }
          return data;
        }

      }
    ));
  }

  alphabeticallySorted(screens) {
    return screens.sort((val1, val2) => {
       if (val1.screenName.toLowerCase() < val2.screenName.toLowerCase()) { return -1; }
       if (val1.screenName.toLowerCase() > val2.screenName.toLowerCase()) { return 1; }
       return 0;
     });
   }

  setActiveLocation(location: Location, index) {
    this.activeLocation.next(location);
    this.activeLocationIndex.next(index);
  }

  resetActiveLocation() {
    this.activeLocation.next(null);
    this.activeLocationIndex.next(0);
  }

  syncAllScreens(companyId) {
    return this.apiService.put('/api/Screens/RefreshCompany/' + companyId, {}, 'json', true).pipe(
    map(
      data => {
        return(data);
      }
    ));
  }

}
