
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CompaniesService } from './companies.service';
import { TimetableService } from './timetable.service';
import { FirebaseService } from './firebase.service';
import { Screen } from '../models/screen.model';
import { FFMPEGService } from './ffmpeg.service';
import { MediaUsage } from '../models/media-usage.model';
import { MediaTypes } from '../models/media-types.model';
import { MediaSearchResponse, MediaResult } from '../models/media';
import 'rxjs/add/observable/forkJoin'

@Injectable()
export class MediaService {
  private mediaSubject = new BehaviorSubject([]);
  public media = this.mediaSubject.asObservable().pipe(distinctUntilChanged());
  public favoriteMedias: MediaResult[] = [];
  public recentMedias: MediaResult[] = [];
  public mediaTypes: MediaTypes[] = [];
  public activeScreenId = undefined;
  public showSpecialTimes = false;
  public leftMenuTabIndex = 0;
  public activeScreen = new BehaviorSubject<Screen>(null);

  constructor(
    private apiService: ApiService,
    private companiesService: CompaniesService,
    private timetableService: TimetableService,
    private firebaseService: FirebaseService,
    private ffmpegService: FFMPEGService
  ) { }

  getMediaTypes(screenId) {
    this.activeScreenId = screenId;
    return this.apiService.get('/api/MediaTypes/' + screenId, 'json', true).pipe(
      map(
        (data: MediaTypes[]) => {
          if (data) { this.mediaTypes = data; }
          return (data);
        }
      ));
  }

  setLeftMenuTabIndex(idx) {
    this.leftMenuTabIndex = idx;
  }

  setShowSpecialTimes(bool) {
    this.showSpecialTimes = bool;
  }

  getFavoriteMedias(companyId) {
    return this.apiService.get('/api/Medias/Favorites?companyId=' + companyId, 'json', true).pipe(
      map(
        (data: MediaSearchResponse) => {
          if (data) { this.favoriteMedias = data.result; }
          return (data);
        }
      ));
  }

  getRecentMedias(companyId) {
    return this.apiService.get('/api/Medias/Recent?page=1&pageSize=20&companyId=' + companyId, 'json', true).pipe(
      map(
        (data: MediaSearchResponse) => {
          if (data) { this.recentMedias = data.result; }
          return (data);
        }
      ));
  }

  getUsedDates(screenId) {
    return this.apiService.get('/api/Screens/' + screenId + '/SpecialDateSelector', 'json', true).pipe(
      map(
        data => {
          return (data);
        }
      ));
  }

  getMediaUsage(mediaId): Observable<MediaUsage[]> {
    return this.apiService.get('/api/Medias/' + mediaId + '/usage', 'json', true);
  }

  createMedia(media) {
    media.companyId = this.companiesService.company.value.companyId;
    // update lastfilechangeon
    return this.apiService.post('/api/Medias/', media, 'json', true).pipe(
      map(
        data => {
          this.setMediaChangeOn();
          return data;
        }
      ));
  }
  updateMedia(media) {
    media.companyId = this.companiesService.company.value.companyId;
    // update lastfilechangeon
    return this.apiService.put('/api/Medias/', media, 'json', true).pipe(
      map(
        data => {
          this.setMediaChangeOn();
          return data;
        }
      ));
  }

  createMediaItem(mediaItem) {
    return this.apiService.post('/api/MediaItems/', mediaItem, 'json', true).pipe(
      map(
        data => {
          this.setMediaChangeOn();
          return data;
        }
      ));
  }

  updateMediaItem(mediaItem) {
    return this.apiService.put('/api/MediaItems/', mediaItem, 'json', true).pipe(
      map(
        data => {
          this.setMediaChangeOn();
          return data;
        }
      ));
  }

  deleteMediaItem(id) {
    return this.apiService.delete('/api/MediaItems/' + id, 'json', true).pipe(
      map(
        data => {
          this.setMediaChangeOn();
          return data;
        }
      ));
  }


  deleteMediaItems(items) {
    const observableBatch = [];

    items.forEach((item, key) => {
      observableBatch.push(this.apiService.delete('/api/MediaItems/' + item.mediaItemId, 'json', true).pipe(map((res: Response) => res)));
    });

    return Observable.forkJoin(observableBatch);
  }

  toggleFavorite(media, index) {
    return this.apiService.toggle('/api/Medias/' + media.mediaId + '/ToggleFavorite', {}, 'json', true).pipe(
      map(
        data => {
          if (media.isFavourite) {
            media.isFavourite = false;
            if (!index) {
              for (let i = 0; i < this.favoriteMedias.length; i++) {
                if (this.favoriteMedias[i].mediaId === media.mediaId) { index = i; }
              }
            }
            this.favoriteMedias.splice(index, 1);
          } else {
            media.isFavourite = true;
            this.favoriteMedias.push(media);
          }
          this.timetableService.reloadTimetable(this.timetableService.activeScreenId.value).subscribe(
            res => {
              // nil
            }
          );
          return (data);
        }
      ));
  }

  uploadFile(file, companyId) {
    return this.apiService.upload('/api/FileManager?companyId=' + companyId, file, 'multipart', true);
  }

  uploadFileWithProgress(file, companyId) {
    return this.apiService.uploadWithProgress('/api/FileManager?companyId=' + companyId, file, 'multipart', true).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  uploadVideoWithProgress(file, companyId) {
    return this.ffmpegService.uploadWithProgress('/api/upload/' + companyId, file, 'multipart', true).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  setMediaChangeOn() {
    let firebaseAuthKey = null;
    if (this.timetableService.activeScreen.value) {
      firebaseAuthKey = this.timetableService.activeScreen.value.authKey;
    }
    if (firebaseAuthKey) {
      this.companiesService.getScreenByAuthKey(firebaseAuthKey).subscribe(data => {
        if (data.id) {
          this.firebaseService.updateScreenMediaStamp(data.id);
        }
      });
    }
  }

}
