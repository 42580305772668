import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'media-elements',
  templateUrl: './media-elements.component.html',
  styleUrls: ['../timeline/timeline.component.scss']
})
export class MediaElementsComponent {
  @Input() mediaTypes;
  @Input() leftMenuTabIndex;

  @Output('openMediaElementDialog')
    change: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

  }

  openMediaElementDialog(mediaType) {
    this.change.emit(mediaType);
  }
}
