import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MediaService, FirebaseService, MediaItem } from '../shared';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FavoriteMediasFoldersDialogComponent } from '../dialogs/favorite-medias-folders-dialog/favorite-medias-folders-dialog.component';
import { FavoriteMediasFolder } from '../shared/models/favoriteMediasFolder.model';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['../timeline/timeline.component.scss', './search.component.scss']
})
export class SearchComponent implements OnInit {


  favoriteFolders: FavoriteMediasFolder[] = [];
  _favoriteMedias: MediaItem[];
  _rootFavoriteMedias: MediaItem[];
  _recentMedias: MediaItem[];

  loadingFavorites = false;

  @Input()
  set favoriteMedias(medias: MediaItem[]) {
    console.log('previous fav medias= ', this._favoriteMedias);
    console.log('new fav medias=', medias);
    this.loadingFavorites = true;
    this._favoriteMedias = medias;
    this.assignFoldersMedias();
  }

  // @Input() favoriteMedias: MediaItem[];
  @Input() recentMedias: MediaItem[];
  @Input() showSearch;
  @Input() filter;
  @Input() leftMenuTabIndex;

  scrollConfig = {
    wheelSpeed: 0.5
  };

  @Output('openMediaElementDialog')
  change: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public mediaService: MediaService,
    private firebaseService: FirebaseService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.getAllFavFolders(); // Get all Favorite Medias Folders form firestore
  }

  getAllFavFolders() {
    this.loadingFavorites = true;
    this.firebaseService.getAllFavoriteMediaFoldersFromCompany().subscribe((res) => {
      this.favoriteFolders = res;
      this.assignFoldersMedias();
    });
  }

  onSearchChange() {
    this.favoriteFolders.forEach((f) => {
      f.isOpen = this.filter.mediaDescription ? true : false;
    });
  }

  assignFoldersMedias() {
    this.loadingFavorites = true;
    this.favoriteFolders.forEach((folder, index) => {
      this.favoriteFolders[index].mediaItems = null;
      this.favoriteFolders[index].mediaItems = [];
      // assign media items to folders by mediaId list
      if (folder.mediaIds) {
        folder.mediaIds.forEach((mediaId) => {
          this._favoriteMedias.forEach((f, findex) => {
            if (f.mediaId === mediaId) {
              this._favoriteMedias[findex].favoriteMediasFoldersId = folder.favoriteMediasFoldersId;
              if (!this.favoriteFolders[index].mediaItems) {
                this.favoriteFolders[index].mediaItems = [];
              }
              this.favoriteFolders[index].mediaItems.push(f);
            }
          });
        });
      }
    });
    // make root favorite media items
    this._rootFavoriteMedias = this._favoriteMedias.filter(f => !f.favoriteMediasFoldersId);
    this.loadingFavorites = false;
  }

  currentYear() {
    return (new Date()).getFullYear();
  }

  openMediaElementDialog(mediaType, event) {
    event.stopPropagation(); // prevent click event on underlying card
    this.change.emit(mediaType);
  }

  toggleFavorite(media, index, event) {
    event.stopPropagation(); // prevent click event on underlying card
    event.preventDefault();
    this.mediaService.toggleFavorite(media, index).subscribe(() => { });
  }

  onClickNewFavoriteFolder() {
    // open favorite medias dialog
    const dialogRef = this.dialog.open(FavoriteMediasFoldersDialogComponent, {
      width: '300px',
      disableClose: false,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log("result from fav medias folder dialog: ", result);
      if (result) {
        if (result.favoriteMediasFoldersId) {
          // new folder was created successfully, add it to local folders
          this.favoriteFolders.push(result);
        }
      }
    },
      err => {
        // err in dialoging (handled by global error toaster)
      });
  }

  onTapFavoriteMediasFolder(folder: FavoriteMediasFolder) {
    folder.isOpen = !folder.isOpen;
  }

  onFolderDrop(folder: FavoriteMediasFolder, event) {
    console.log(folder, event);
    if (folder && event.dragData) {
      const mediaItem: MediaItem = event.dragData.media;
      const prevFolder = event.dragData.folder;
      if (folder.favoriteMediasFoldersId && mediaItem.mediaId) {
        if (!folder.mediaItems) {
          console.log('new folder mediaItems array', folder, this.favoriteFolders);
          folder.mediaItems = [];
        }
        if (!prevFolder) {
          console.log('no prev folder', mediaItem);
          this._rootFavoriteMedias.splice(this._rootFavoriteMedias.indexOf(mediaItem), 1);
        } else {
          console.log('prevfolder found', prevFolder);

          const newMediaItems = [];
          const folderIndex = null;
          let prevFolderIndex = null;

          this.favoriteFolders.forEach((f, index) => {
            if (f.favoriteMediasFoldersId !== folder.favoriteMediasFoldersId) {
              console.log('f', f, index);
              if (f.favoriteMediasFoldersId === prevFolder.favoriteMediasFoldersId) {
                prevFolderIndex = index;
                console.log('set folderindex', prevFolderIndex);
                this.favoriteFolders[prevFolderIndex].mediaItems.splice(this.favoriteFolders[prevFolderIndex].mediaItems.indexOf(mediaItem), 1);
                // f.mediaItems.forEach((m) => {
                //   if (m.mediaId !== mediaItem.mediaId) {
                //     newMediaItems.push(m);
                //   }
                // });
              }
            } else {
              const exists = f.mediaItems.find((m) => m.mediaId === mediaItem.mediaId);

              if (exists) {
                console.log('remove, exists already', exists);
                f.mediaItems.splice(f.mediaItems.indexOf(mediaItem), 1); // remove last added mediaItem, because it exists already in this folder
              }
            }
          });

          if (folderIndex !== null) {
            // this.favoriteFolders[folderIndex].mediaItems = newMediaItems;
            console.log('neue', this.favoriteFolders);
          }

          // this.favoriteFolders = newFolders;
        }


        folder.isOpen = true;
        folder.mediaItems.push(mediaItem);
        this.saveFolders();
      }
    }
  }

  saveFolders() {
    const folders = [];
    this.favoriteFolders.forEach((f, i) => {
      const folder = {
        folderName: f.folderName,
        favoriteMediasFoldersId: f.favoriteMediasFoldersId,
        mediaIds: f.mediaItems ? f.mediaItems.map(m => m.mediaId) : []
      };
      folders.push(folder);
    });
    this.firebaseService.saveFavoriteMediasFolders(folders).subscribe((res) => {
      // saving of folders done
    });
  }
}
