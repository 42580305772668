import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FirebaseService } from '../../shared';
import { Ng2ImgToolsService } from 'ng2-img-tools';
import * as EXIF from 'exif-js';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'module-uploader',
  templateUrl: './module-uploader.component.html',
  styleUrls: ['./module-uploader.component.scss']
})
export class ModuleUploaderComponent implements OnInit {

  previewUrl: any;


  @Input() moduleUploader;
  @Input() customConfig;
  @Input() data;
  @Input() uploaderIndex;
  @ViewChild('previewImage', { static: false }) imgEl: ElementRef;

  orientedImage: HTMLImageElement = null;
  resizedImage: string = null;
  compressedImage: string = null;
  selectedImage: string = null;
  croppedImage: string = null;
  resizedExactCroppedImage: string = null;
  resizedExactFilledImage: string = null;
  resizedImageTrusted: SafeUrl = null;
  compressedImageTrusted: SafeUrl = null;
  selectedImageTrusted: SafeUrl = null;
  croppedImageTrusted: string = null;
  resizedExactCroppedImageTrusted: string = null;
  resizedExactFilledImageTrusted: string = null;
  constructor(private ng2ImgToolsService: Ng2ImgToolsService, private sanitizer: DomSanitizer, private zone: NgZone,
    private firebase: FirebaseService) {
  }

  ngOnInit() { }

  public fileChange(event: any) {
    this.moduleUploader.uploading = true;
    console.log(event)
    if (event.target.files.length > 0) {
      this.processFile(event.target.files[0]);
    }
  }
  private processFile(file: File) {
    if (this.resizedImage !== null) {
      window.URL.revokeObjectURL(this.resizedImage);
    }
    if (this.compressedImage !== null) {
      window.URL.revokeObjectURL(this.compressedImage);
    }
    if (this.selectedImage !== null) {
      window.URL.revokeObjectURL(this.selectedImage);
    }
    if (this.croppedImage !== null) {
      window.URL.revokeObjectURL(this.croppedImage);
    }
    if (this.resizedExactCroppedImage !== null) {
      window.URL.revokeObjectURL(this.resizedExactCroppedImage);
    }
    if (this.resizedExactFilledImage !== null) {
      window.URL.revokeObjectURL(this.resizedExactFilledImage);
    }
    this.resizedImage = "processing";
    this.compressedImage = "processing";
    this.croppedImage = "processing";
    this.resizedExactCroppedImage = "processing";
    this.resizedExactFilledImage = "processing";
    this.selectedImage = window.URL.createObjectURL(file);
    this.selectedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(this.selectedImage);
    // this.compressImage(file);
    this.resizeImage(file);
    // this.cropImage(file);
    // this.resizeExactCropImage(file);
    // this.resizeExactFillImage(file);
  }
  private compressImage(file: File) {
    console.info("Starting compression for file:", file);
    this.ng2ImgToolsService.compress([file], 0.3).subscribe(result => {
      console.log("Compression result:", result);
      //all good
      this.compressedImage = window.URL.createObjectURL(result);
      this.compressedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(this.compressedImage);
    }, error => {
      console.error("Compression error:", error);
    }
    );
  }
  private resizeImage(file: File) {
    console.info("Starting resize for file:", file);
    this.ng2ImgToolsService.resize([file], 400, 500).subscribe(result => {
      console.log("Resize result:", result);
      //all good
      this.resizedImage = window.URL.createObjectURL(result);
      this.resizedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(this.resizedImage);

      let img = new Image();
      img.onload = () => {
        console.log("onload")
        this.ng2ImgToolsService.getEXIFOrientedImage(img).then(orientedImage => {
          console.log("oriented;;", orientedImage);
          this.orientedImage = orientedImage;
          this.upload(orientedImage.src);
        })

      }
      img.src = this.resizedImage;
    }, error => {
      console.error("Resize error:", error);
    }
    );
  }


  deleteMensaMaxImg(id) {
    this.moduleUploader.deleting = true;
    this.firebase.deleteMensaMaxImg(id, this.uploaderIndex).subscribe((res) => {
      // deletion complet
      this.moduleUploader.signedUrl = null;
      this.moduleUploader.deleting = false;
    });
  }

  upload(base64) {
    const body = {
      base64: base64.split(',')[1]
    };
    this.firebase.uploadMensaMaxMenuImage(this.customConfig.menuplanId, this.uploaderIndex, 'img_mensamax-' + this.customConfig.menuplanId + '-' + this.uploaderIndex + '.jpeg', body).subscribe(
      (res) => {
        this.moduleUploader.uploading = false;
        this.moduleUploader.signedUrl = null;
        if (res.url) {
          this.firebase.getMensaMaxMenuImage(this.customConfig.menuplanId, this.uploaderIndex.toString());
        }

      }
    );
  };

  onTapImg(src) {
    window.open(src, '_blank');
  }





}
