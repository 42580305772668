import { Component, OnInit, ApplicationRef } from '@angular/core';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { first, concat } from 'rxjs/operators';
import { interval, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  production = false;
  navigatorObj: any = window.navigator;

  constructor(
    public translate: TranslateService,
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    private snackBar: MatSnackBar
  ) {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const languages = environment.languages;
    const lang = 'en';
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(lang);
    let localLang: string;
    let localSet = false;
    // try initialize language from localstorage (user setting preference)
    try {
      localLang = localStorage.getItem('lang');
      if (languages.includes(localLang)) {
        translate.use(localLang);
        localSet = true;
      }
    } catch (error) {
      console.log('error translation local', error);
    }
    // if local language not initialized, try initialize from browser language
    if (!localSet) {
      let navLang: string;
      try {
        navLang = this.navigatorObj.language.split('-')[0];
        if (languages.includes(navLang)) {
          translate.use(navLang);
        } else {
          translate.use(lang);
        }
      } catch (error) {
        translate.use(lang);
      }
    }
    if (location.hostname !== 'localhost') {
      updates.available.subscribe(event => {
        if (event.current !== event.available) {
          this.snackBar.open('New Update available, activating...', null, {
            duration: 3000
          });
          const timer1 = timer(3000);
          const timer1$ = timer1.subscribe((val) => {
            updates.activateUpdate().then(() => this.updateApp());
          });
        } else {
          // console.log('pwa is up to date.');
        }
      });
      // updates.activated.subscribe(event => {
      //   console.log('old version was', event.previous);
      //   console.log('new version is', event.current);
      // });

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everyMinute$ = interval(60000);
      const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

      everyMinute$.subscribe(() => {
        // console.log('check for updates tick...');
        updates.checkForUpdate();
      });
    }
  }

  ngOnInit() {
    this.production = environment.production;
    // Detect if User is touching the screen
    this.detectUserTouchInput();
  }

  detectUserTouchInput() {
    window.localStorage.setItem('userInputType', 'normal');
    window.addEventListener('touchstart', function onFirstTouch() {
      // Add .css Class to <body>
      document.body.classList.add('user-is-touching');
      // save to localStorage
      window.localStorage.setItem('userInputType', 'touch');
      // we only need to know once that a human touched the screen, so we can stop listening now
      window.removeEventListener('touchstart', onFirstTouch, false);
    }, false);
  }

  updateApp() {
    document.location.reload();
    // console.log('The app is updating right now');
  }
}
