import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {MenuDialog} from '../dialogs/menu-dialog.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {NgGrid, NgGridConfig, NgGridItemConfig, NgGridItemEvent} from 'angular2-grid';
import {SpecialDateDialog} from '../dialogs/special-date-dialog.component';
import {ScreenDialog} from '../dialogs/screen-dialog.component';
import {MediaElementDialog} from '../dialogs/media-element-dialog';
import {ConfirmDeleteDialog} from '../dialogs/confirm-delete-dialog.component';
import {CompaniesService, Company, LocationsService, Media, MediaService, TimetableService, User, UserService} from '../shared';
import * as moment from 'moment';
import {TimelineType} from '../shared/enums/timeline-type.enum';
import {appLog} from '../shared/utils/app-log.util';

interface Box {
  id: number;
  config: any;
}

@Component({
  selector: 'app-timeline',
  animations: [
    trigger('errorAnimation', [
      transition(':leave', [
        style({'opacity': 1}),
        animate('250ms cubic-bezier(0.55, 0.055, 0.675, 0.19)', style({'opacity': 0}))
      ]),
      transition(':enter', [
        animate('500ms cubic-bezier(0.215, 0.61, 0.355, 1)', keyframes([
          style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
          style({opacity: 0.5, transform: 'translateY(35px)', offset: 0.5}),
          style({opacity: 0.8, transform: 'translateY(-20px)', offset: 0.8}),
          style({opacity: 1, transform: 'translateY(0)', offset: 1.0})
        ]))
      ])
    ]),
    trigger('solidDropAnimation', [
      // transition(':leave', [
      //     style({'opacity': 1}),
      //     animate('250ms cubic-bezier(0.55, 0.055, 0.675, 0.19)', style({'opacity': 0}))
      // ]),
      transition(':enter', [
        animate('220ms cubic-bezier(0.215, 0.61, 0.355, 1)', keyframes([
          style({opacity: 0, transform: 'translateY(180%)', offset: 0}),
          style({opacity: 0.5, transform: 'translateY(0)', offset: 0.5}),
          style({opacity: 0.8, transform: 'translateY(20px)', offset: 0.8}),
          style({opacity: 1, transform: 'translateY(0)', offset: 1.0})
        ]))
      ])
    ]),
    trigger('stepAnimation', [
      transition(':leave', [
        style({'opacity': 1}),
        animate('250ms cubic-bezier(0.55, 0.055, 0.675, 0.19)', style({transform: 'scale(0)', opacity: 0}))
      ]),
      transition(':enter', [
        animate('500ms cubic-bezier(0.215, 0.61, 0.355, 1)', keyframes([
          style({opacity: 0, transform: 'scale(0)', offset: 0}),
          style({opacity: 1, transform: 'scale(1.0)', offset: 1.0})
        ]))
      ])
    ]),
    trigger('slideInRightAnimation', [
      transition(':leave', [
        style({'opacity': 1}),
        animate('250ms cubic-bezier(0.55, 0.055, 0.675, 0.19)', style({transform: 'scale(0)', opacity: 0}))
      ]),
      transition(':enter', [
        animate('500ms cubic-bezier(0.215, 0.61, 0.355, 1)', keyframes([
          style({opacity: 0, transform: 'translateX(-100%)', offset: 0}),
          style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
        ]))
      ])
    ]),
    trigger('slideInLeftAnimation', [
      transition(':leave', [
        style({'opacity': 1}),
        animate('250ms cubic-bezier(0.55, 0.055, 0.675, 0.19)', style({transform: 'scale(0)', opacity: 0}))
      ]),
      transition(':enter', [
        animate('500ms cubic-bezier(0.215, 0.61, 0.355, 1)', keyframes([
          style({opacity: 0, transform: 'translateX(200%)', offset: 0}),
          style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
        ]))
      ])
    ]),
    trigger('spinAnimation', [
      transition(':leave', [
        style({'opacity': 1}),
        animate('250ms cubic-bezier(0.55, 0.055, 0.675, 0.19)', style({transform: 'scale(0)', opacity: 0}))
      ]),
      transition(':enter', [
        animate('0.82s cubic-bezier(.36,.07,.19,.97)', keyframes([
          style({opacity: 0, transform: 'translate3d(-1px, 0, 0)', offset: 0}),
          style({opacity: 0.1, transform: 'translate3d(2px, 0, 0)', offset: 0.6}),
          style({opacity: 0.2, transform: 'translate3d(-4px, 0, 0)', offset: 0.8}),
          style({opacity: 0.4, transform: 'translate3d(4px, 0, 0)', offset: 1.0})
        ]))
      ])
    ])
  ],
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgGrid, {static: false})
  private grid: NgGrid;

  currentUser: User = new User();
  allLocations = [];
  favoriteMedias = [];
  recentMedias = [];
  timetable = [];
  specialTimetable = [];
  days = [];
  mediaTypes = [];
  showSpecialTimes = false;
  selectedDate = undefined;
  selectedDates = [];
  viewDate = undefined;
  showSearch = true;
  filter: Media = new Media();
  leftMenuTabIndex = 0;
  timeTabIndex = 0;
  activeScreenId: string;
  activeScreen: any;
  times = [];
  boxes: Array<Box> = [];
  boxBeingDragged = false;
  company: Company = new Company();
  showFavorites = true;
  showRecent = false;
  isTimetableLocked = false;

  params = {
    screenId: null,
    mode: null,
    selectedDate: null,
    locId: null,
    companyId: null
  };
  loading = {
    locations: false,
    favorites: false,
    recent: false,
    timetable: false,
    mediaDialog: false,
    specialTimetable: false,
    syncAll: false,
    companies: false,
    usedDates: false
  };
  gridConfig: NgGridConfig = <NgGridConfig>{
    'margins': [0],
    'draggable': true,
    'resizable': true,
    'max_cols': 1,
    'max_rows': 0,
    'visible_cols': 1,
    'visible_rows': 0,
    'min_cols': 1,
    'min_rows': 1,
    'col_width': 5,
    'row_height': 20,
    'cascade': 'off',
    'min_width': 250,
    'min_height': 20,
    'fix_to_grid': true,
    'auto_style': true,
    'auto_resize': true,
    'maintain_ratio': false,
    'prefer_new': false,
    'zoom_on_drag': false,
    'limit_to_screen': true
  };
  selectedTime = {
    name: undefined,
    halfHour: undefined
  };
  scrollConfig = {
    wheelSpeed: 0.5,
    alwaysVisible: true,
    maxScrollbarLength: 150,
    suppressScrollX: true
  };

  private itemPositions: Array<any> = [];
  private rgb = '#efefef';
  private curNum;
  private activeBoxTimeTableId: string;

  // Subscriptions
  private companySubscription;
  private activeScreenSubscription;

  usedDates: string[];

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public locationsService: LocationsService,
    public companiesService: CompaniesService,
    public timetableService: TimetableService,
    public mediaService: MediaService,
    public snackBar: MatSnackBar
  ) {
    this.currentUser = userService.getCurrentUser();
    this.days = timetableService.days;
    if (this.currentUser.companyId) {
      this.getAllLocations(this.currentUser.companyId);
      this.getFavoriteMedias(this.currentUser.companyId);
      this.getRecentMedias(this.currentUser.companyId);
    }
    this.buildTimes();
    this.companySubscription = this.companiesService.company.subscribe(
      value => {
          if (value) {
            this.company = value;
            this.recentMedias.length = 0;
            this.favoriteMedias.length = 0;
            this.getAllLocations(this.company.companyId);
            this.getFavoriteMedias(this.company.companyId);
            this.getRecentMedias(this.company.companyId);
          }
      }
    );
    this.activeScreenSubscription = this.timetableService.activeScreenId.subscribe(
      value => {
        const screenId = this.activeScreen ? this.activeScreen.screenId : null;

        if (value !== screenId) {
          this.timetableService.getSingleScreen().subscribe(data => this.activeScreen = this.timetableService.activeScreen.value);
          this.getUsedDates(value);
        }
      }
    );
  }

  ngOnInit() {
    this.params.screenId = this.route.snapshot.paramMap.get("screenId");
    this.params.mode =  this.route.snapshot.paramMap.get("mode");
    this.params.selectedDate =  this.route.snapshot.paramMap.get("selectedDate");
    this.params.locId =  this.route.snapshot.paramMap.get("locationId");
    this.params.companyId =  this.route.snapshot.paramMap.get("companyId");
    this.companiesService.companyId.next(this.params.companyId);

    if (!this.currentUser.access_token) { this.router.navigateByUrl('auth/login'); }
    this.boxes = this.timetableService.boxes;
    this.timetable = this.timetableService.timetableSubject.value;
    this.timeTabIndex = this.timetableService.timeTabIndex.value;
    if (window.innerWidth > 1099) { this.scrollConfig.wheelSpeed = 1.0; }
  }

  ngAfterViewInit(): void {
    if (this.isMobile()) {
      this.leftMenuTabIndex = 1;
    }
  }

  ngOnDestroy() {
    // Destroy subscriptions
    this.activeScreenSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
  }

  getUsedDates(screenId) {
    this.loading.usedDates = true;
    this.mediaService.getUsedDates(screenId)
      .subscribe(
        data => {
          const future = [];
          data.dates.forEach((d) => {
            if (moment(d).isAfter(moment().subtract(1, 'days'))) {
              future.push(d);
            }
          })
          this.usedDates = future;

          this.loading.usedDates = false;
        },
        err => {
          this.loading.usedDates = false;
        }
      );
  }

  toggleIconFilter(type) {
    if (type === 'showFavorites') {
      this.showFavorites = !this.showFavorites;
      this.showRecent = !this.showFavorites;
    }
    if (type === 'showRecent') {
      this.showRecent = !this.showRecent;
      this.showFavorites = !this.showRecent;
    }
  }

  isMobile(): boolean {
    return (window.localStorage.getItem('userInputType') === 'touch') || (window.innerWidth < 1100) ? true : false;
  }

  switchToOverview() {
    const compId = this.companiesService.company.value.companyId;
    const locId = this.locationsService.activeLocation.value.locationId;
    this.router.navigateByUrl('overview/' + compId + '/' + locId);
  }

  getTimeTabIndex() {
    return this.timetableService.timeTabIndex.value;
  }

  getViewDate() {
    return this.timetableService.selectedDate.value.local().format('dddd, D. MMMM YYYY');
  }

  getShortDate(d) {
    return d.local().format('DD.MM.YYYY')
  }

  changeCompany(company) {
    this.company = company;
    this.recentMedias.length = 0;
    this.favoriteMedias.length = 0;
    this.getAllLocations(this.company.companyId);
    this.getFavoriteMedias(this.company.companyId);
    this.getRecentMedias(this.company.companyId);
  }

  onClickHourWrapper() {
    if (window.innerWidth > 1099) {
      if (this.leftMenuTabIndex !== 2) { this.changeLeftMenuTabIndex(1); }
    } else {
      this.openMenuDialog('new');
    }
  }

  onTimetableDrop(time, event, halfHour) {
    this.selectedTime.name = time.name;
    this.selectedTime.halfHour = halfHour;
    this.openMediaElementDialog(event.dragData.media ? event.dragData.media : event.dragData, {}, false, this.showSpecialTimes);
  }

  lockingTimetable(lock: boolean): void {
    this.isTimetableLocked = lock;

    if (lock) {
      this.grid.disableDrag();
      this.grid.disableResize();
    } else {
      this.grid.enableDrag();
      this.grid.enableResize();
    }
  }

  // index 0: Locations / Screens
  // index 1: Media Elements
  // index 2: Module
  changeLeftMenuTabIndex(index) {
    const tmp = this.leftMenuTabIndex;
    this.leftMenuTabIndex = index;
    this.mediaService.setLeftMenuTabIndex(index);
    if (index === 2) { this.showSpecialTimes = false; }
    if (tmp === 2 || index === 2) {
      this.boxes.length = 0;
      setTimeout(() => {
        this.reloadTimetable();
      }, 0);
    }
  }

  onSelectMedia(media) {
    this.changeLeftMenuTabIndex(1);
    this.openMediaElementDialog(media, {}, false, this.showSpecialTimes);
  }

  onSelectModule(media) {
    this.changeLeftMenuTabIndex(2);
    this.openMediaElementDialog(media, {}, false, this.showSpecialTimes);
  }

  getAllLocations(companyId) {
    this.boxes.length = 0;
    this.loading.locations = true;
    this.locationsService.getAllLocationsWithScreens(companyId)
      .subscribe(
        data => {
          this.loading.locations = false;
          this.allLocations = data;
          if (this.allLocations) {
            if (this.params.screenId && this.params.locId) {
              // make sure that this screenId exists on this company
              let belongs = false;
              this.allLocations.forEach((location) => {
                if (location.screens) {
                  location.screens.forEach((screen) => {
                    if (screen.screenId === this.params.screenId) {
                      belongs = true;
                    }
                  });
                }
              });
              if (belongs) {
                this.activeScreenId = this.params.screenId;
              } else {
                this.activeScreenId = data[0].screens[0].screenId;
                // this.router.navigateByUrl('dashboard/timeline');
              }
            } else if (!this.params.screenId && this.params.locId) {
              this.allLocations.forEach(
                (location, i) => {
                  if (location.locationId === this.params.locId) {
                    if (location.screens.length) {
                      // We have no screenId, so make the first Screen - from
                      // this location - the active screen.
                      this.activeScreenId = this.allLocations[i].screens[0].screenId;
                    } else {
                      // Make sure no timetable is shown!
                    }
                  }
                }
              );
            } else {
              this.activeScreenId = data[0].screens[0].screenId;
            }
            if (this.params.mode) {
              switch (this.params.mode) {
                case 'module': {
                  this.changeLeftMenuTabIndex(2);
                  break;
                }
                case 'standard': {
                  this.onChangeTimetableTime(0);
                  break;
                }
                case 'monday': {
                  this.onChangeTimetableTime(1);
                  break;
                }
                case 'tuesday': {
                  this.onChangeTimetableTime(2);
                  break;
                }
                case 'wednesday': {
                  this.onChangeTimetableTime(3);
                  break;
                }
                case 'thursday': {
                  this.onChangeTimetableTime(4);
                  break;
                }
                case 'friday': {
                  this.onChangeTimetableTime(5);
                  break;
                }
                case 'saturday': {
                  this.onChangeTimetableTime(6);
                  break;
                }
                case 'sunday': {
                  this.onChangeTimetableTime(7);
                  break;
                }
                case 'specialDate': {
                  if (this.leftMenuTabIndex === 2) { this.changeLeftMenuTabIndex(1); }
                  this.showSpecialTimes = true;
                  this.selectedDate = moment(this.params.selectedDate);
                  this.viewDate = moment(this.params.selectedDate).local().format('dddd, D. MMMM YYYY');
                  this.onChangeSelectedDate(moment(this.params.selectedDate));
                }
              }
            } else {
              this.getTimetable(this.activeScreenId);
            }
            this.getMediaTypes(this.activeScreenId);
          }
        },
        err => this.loading.locations = false
      );
  }

  onChangeScreen(screenId) {
    this.boxes.length = 0;
    this.activeScreenId = screenId;
   
    this.getMediaTypes(this.activeScreenId);
    if (this.showSpecialTimes) {
      this.getSpecialTimetable(this.selectedDate.format('YYYY-MM-DD'));
    } else {
      this.getTimetable(this.activeScreenId);
    }
  }

  onAddScreen(screen) {
    this.allLocations.forEach((location) => {
      if (location.locationId === screen.locationId) {
        location.screens.push(screen);
      }
    });
    this.boxes.length = 0;
    this.activeScreenId = screen.screenId;

    this.getMediaTypes(this.activeScreenId);
    if (this.showSpecialTimes) {
      this.getSpecialTimetable(this.selectedDate.format('YYYY-MM-DD'));
    } else {
      this.getTimetable(this.activeScreenId);
    }
  }

  onChangeTimetableTime(index) {
    this.loading.timetable = true;
    this.timetableService.changeDay(this.activeScreenId, index)
      .subscribe(
        data => {
          this.loading.timetable = false;
        },
        err => this.loading.timetable = false
      );
  }

  onChangeSelectedDate(date) {
    this.loading.timetable = true;
    this.timetableService.changeDate(this.activeScreenId, date)
      .subscribe(
        () => this.loading.timetable = false,
        () => this.loading.timetable = false
      );
  }

  getFavoriteMedias(companyId) {
    this.loading.favorites = true;
    this.mediaService.getFavoriteMedias(companyId)
      .subscribe(
        data => {
          this.loading.favorites = false;
          this.favoriteMedias = this.mediaService.favoriteMedias;
        },
        () => this.loading.favorites = false
      );
  }

  getRecentMedias(companyId) {
    this.loading.recent = true;
    this.mediaService.getRecentMedias(companyId)
      .subscribe(
        data => {
          this.loading.recent = false;
          if (data) { this.recentMedias = data.result; }
        },
        err => this.loading.recent = false
      );
  }

  getTimetable(screenId) {
    this.timetable = null;
    this.loading.timetable = true;
    this.timetableService.getTimetable(screenId)
      .subscribe(
        data => {
          this.loading.timetable = false;
          this.timetable = this.timetableService.timetableSubject.value;
        },
        () => this.loading.timetable = false
      );
  }

  reloadTimetable() {
    this.loading.timetable = true;
    if (this.showSpecialTimes) {
      this.timetableService.getSpecialTimetable(this.activeScreenId)
        .subscribe(
          data => {
            this.loading.timetable = false;
            this.timetable = this.timetableService.timetableSubject.value;
          },
          err => this.loading.timetable = false,
          () => this.lockingTimetable(false)
        );
    } else if (this.leftMenuTabIndex === 2) {
      this.timetableService.getModuleTimetable(this.activeScreenId)
        .subscribe(
          data => {
            this.loading.timetable = false;
            this.timetable = this.timetableService.timetableSubject.value;
          },
          () => this.loading.timetable = false,
          () => this.lockingTimetable(false)
        );
    } else {
      this.timetableService.reloadTimetable(this.activeScreenId)
        .subscribe(
          data => {
            this.loading.timetable = false;
            this.timetable = this.timetableService.timetableSubject.value;
          },
          () => this.loading.timetable = false,
          () => this.lockingTimetable(false)
        );
    }
  }

  getSpecialTimetable(date) {
    this.timetable = null;
    this.loading.timetable = true;
    this.timetableService.getSpecialTimetable(this.activeScreenId)
      .subscribe(
        data => {
          this.loading.timetable = false;
          this.timetable = this.timetableService.timetableSubject.value;
        },
        () => this.loading.timetable = false
      );
  }

  closeSpecialTimetable() {
    this.showSpecialTimes = false;
    this.reloadTimetable();
  }

  removeBox(box, index: number, event): void {
    event.stopPropagation();
    const promptRef = this.dialog.open(ConfirmDeleteDialog, {
      width: '380px',
      disableClose: true,
    });
    promptRef.afterClosed().subscribe(answer => {
      if (answer) {
        if (this.showSpecialTimes) {
          this.timetableService.deleteSingleSpecialFromTimetable(this.activeScreenId, box.timeTableId)
            .subscribe(
              data => {
                this.snackBar.open('Spezialeintrag erfolgreich gelöscht', undefined, {
                  duration: 3000
                });
                this.getRecentMedias(this.companiesService.company.value.companyId);
                this.getFavoriteMedias(this.companiesService.company.value.companyId);
                this.reloadTimetable();
              },
              err => this.reloadTimetable()
            );
        } else if (this.leftMenuTabIndex === 2) {
          this.timetableService.deleteModuleFromTimetable(this.activeScreenId, box.timeTableId)
            .subscribe(
              data => {
                this.snackBar.open('Moduleintrag erfolgreich gelöscht', undefined, {
                  duration: 3000
                });
                this.getRecentMedias(this.companiesService.company.value.companyId);
                this.getFavoriteMedias(this.companiesService.company.value.companyId);
                this.reloadTimetable();
              },
              () => this.reloadTimetable()
            );
        } else {
          const day = this.days[this.timetableService.timeTabIndex.value];
          this.timetableService.deleteFromTimetable(this.activeScreenId, day.timeTableType, day.timeTableValue, box.timeTableId)
            .subscribe(
              data => {
                this.snackBar.open('Timetable-Eintrag erfolgreich gelöscht', undefined, {
                  duration: 3000
                });
                this.getRecentMedias(this.companiesService.company.value.companyId);
                this.getFavoriteMedias(this.companiesService.company.value.companyId);
                this.reloadTimetable();
              },
              () => this.reloadTimetable()
            );
        }
      }
    });
  }

  moveTimeBox(data): void {
    switch (data.box.timeTableType.toLowerCase()) {
      case TimelineType.SPECIAL:
        this.timetableService.deleteSingleSpecialFromTimetable(this.activeScreenId, data.box.timeTableId).subscribe(() => {});
        break;
      case TimelineType.MODULE:
        this.timetableService.deleteModuleFromTimetable(this.activeScreenId, data.box.timeTableId).subscribe(() => {});
        break;
      default:
        this.timetableService.deleteFromTimetable(
          this.activeScreenId,
          data.box.timeTableType,
          data.box.timeTableValue,
          data.box.timeTableId
        );
    }

    data.box.timeTableType = data.box.day.timeTableType;
    data.box.timeTableValue = data.box.day.timeTableValue;
    this.createTimeBox(data);
  }

  findTimeByRow(row) {
    let hour;
    let minutes;
    if (row % 2 === 0) {
      hour = (row - 2) / 2;
      minutes = 30;
    } else {
      hour = (row - 1) / 2;
      minutes = 0;
    }
    const time = {
      'hour': hour,
      'minutes': minutes,
      'name': this.timetableService.buildTimeName(hour, minutes)
    };
    return time;
  }

  findstopTimeByCoords(row, sizey) {
    const startTime = this.findTimeByRow(row);
    let hour, minutes;
    const endRow = row + sizey;
    if (endRow % 2 === 0) {
      hour = (endRow - 2) / 2;
      minutes = 30;
    } else {
      hour = (endRow - 1) / 2;
      minutes = 0;
    }
    const stopTime = {
      'hour': hour,
      'minutes': minutes,
      'name': this.timetableService.buildTimeName(hour, minutes)
    };
    return stopTime;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  addSingleBox(data) {
    const conf: NgGridItemConfig = this.timetableService._generateDefaultItemConfig();
    const startDate = new Date(data.startTime);
    const startHour = startDate.getUTCHours();
    const startMinutes = startDate.getUTCMinutes();
    const endDate = new Date(data.endTime);
    let endHour = endDate.getUTCHours();
    const day = endDate.getUTCDay();
    if (day === 2) { endHour = 24; }
    const endMinutes = endDate.getUTCMinutes();
    const startRow = this.timetableService.findRowByTime(startHour, startMinutes);
    const sizeY = this.timetableService.findSizeYByTime(startHour, startMinutes, endHour, endMinutes);
    conf.row = startRow;
    conf.sizey = sizeY;
    conf.payload = this.boxes.length + 1;
    const b = {
      'id': conf.payload,
      'config': conf,
      'media': data.media,
      'startDate': startDate,
      'endDate': endDate,
      'startMinutes': startMinutes,
      'startHour': startHour,
      'endHour': endHour,
      'endMinutes': endMinutes,
      'mediaId': data.mediaId,
      'screenId': data.screenId,
      'timeTableId': data.timeTableId,
      'timeTableType': data.timeTableType,
      'timeTableValue': data.timeTableValue,
      'row': startRow,
      'sizey': sizeY,
      'startTime': {
        'minutes': startMinutes,
        'hour': startHour,
        'name': this.timetableService.buildTimeName(startHour, startMinutes)
      },
      'stopTime': {
        'minutes': endMinutes,
        'hour': endHour,
        'name': this.timetableService.buildTimeName(endHour, endMinutes)
      }
    };
    this.boxes.push(b);
  }

  buildTimes(): void {
    const t = [];
    for (let i = 0; i < 24; i++) {
      const hour = {
        id: i.toString(),
        name: (i < 10 ? '0' : '') + i + ':00'
      };
      t.push(hour);
    }
    this.times = t;
  }

  onResizeStop(index: number, event: NgGridItemEvent, box, mediaType): void {
    setTimeout(() => this.updateExistedBlock(mediaType, box), 0);
  }

  getMediaTypes(screenId) {
    this.mediaService.getMediaTypes(screenId)
      .subscribe(
        data => {
          this.mediaTypes = data;
        }
      );
  }

  updateTimeBox(dialogData) {
    this.lockingTimetable(true);

    const timeBox = {
      'timeTableId': dialogData.box.timeTableId,
      'startTime': '1900-01-01T' + dialogData.box.startTime.name + ':00',
      'endTime': '1900-01-' + (dialogData.box.stopTime.hour === 24 ? '02T00:00' : '01T' + dialogData.box.stopTime.name) + ':00',
      'timeTableType': this.capitalizeFirstLetter(dialogData.box.timeTableType),
      'timeTableValue': dialogData.box.timeTableValue,
      'mediaId': dialogData.media.mediaId
    };

    if (dialogData.media.customType) {
      timeBox.timeTableType = 'Module';
      timeBox.timeTableValue = null;
    }
    if (dialogData.showSpecialTimes) {
      timeBox.timeTableType = 'Special';
      timeBox.timeTableValue = dialogData.selectedDate.format('YYYY-MM-DD');
    }
    this.timetableService.putTimetable(timeBox)
      .subscribe(
        data => {
          if (this.timetable) {
            const leng = this.timetable.length;
            for (let i = 0; i < leng; i++) {
              if (this.timetable[i].timeTableId === dialogData.box.timeTableId) {
                this.timetable[i].startTime = '1900-01-01T' + dialogData.box.startTime.name + ':00';
                // tslint:disable-next-line:max-line-length
                this.timetable[i].endTime = '1900-01-' + (dialogData.box.stopTime.hour === 24 ? '02T00:00' : '01T' + dialogData.box.stopTime.name) + ':00';
              }
            }
          }
          this.snackBar.open('Eintrag gespeichert', undefined, {
            duration: 3000,
            horizontalPosition: 'start'
          });
          this.getRecentMedias(this.companiesService.company.value.companyId);
          this.reloadTimetable();
        },
        () => this.lockingTimetable(false)
      );
  }

  createTimeBox(dialogData) {
    const timeBox = {
      'screenId': this.activeScreenId,
      'startTime': '1900-01-01T' + dialogData.box.startTime.name + ':00',
      'endTime': '1900-01-' + (dialogData.box.stopTime.hour === 24 ? '02T00:00' : '01T' + dialogData.box.stopTime.name) + ':00',
      'timeTableType': this.capitalizeFirstLetter(dialogData.box.day.timeTableType),
      'timeTableValue': dialogData.box.day.timeTableValue,
      'mediaId': dialogData.media.mediaId
    };
    if (dialogData.media.customType) {
      timeBox.timeTableType = 'Module';
      timeBox.timeTableValue = null;
    }
    if (dialogData.showSpecialTimes) {
      timeBox.timeTableType = 'Special';
      timeBox.timeTableValue = dialogData.selectedDate.format('YYYY-MM-DD');
    }
    this.timetableService.postTimetable(timeBox)
      .subscribe(
        data => {
          const activeTimeTableValue = this.days[this.timetableService.timeTabIndex.value].timeTableValue;
          // check if the day of the new item is selected
          if (activeTimeTableValue === data.timeTableValue) {
            this.addSingleBox(data);
          }
          this.snackBar.open('Eintrag gespeichert', undefined, {
            duration: 3000
          });
          this.reloadTimetable();
        }
      );
  }

  openReferences(media, box, event) {
    event.stopPropagation(); // prevent click event on underlying card
    this.openMediaElementDialog(media, box, true, this.showSpecialTimes);
  }

  toggleFavorite(media, index, event) {
    event.stopPropagation(); // prevent click event on underlying card
    this.mediaService.toggleFavorite(media, index);
  }

  openMenuDialog(target) {
    const dialogRef = this.dialog.open(MenuDialog, {
      width: '600px',
      disableClose: false,
      data: {
        target: target,
        showSpecialTimes: this.showSpecialTimes,
        activeScreenId: this.activeScreenId,
        allLocations: this.allLocations,
        newScreenId: undefined,
        mediaTypes: this.mediaTypes,
        newMediaType: {},
        newMediaFromSearch: {},
        showSearch: this.showSearch,
        recentMedias: this.recentMedias,
        favoriteMedias: this.favoriteMedias,
        filter: this.filter,
        leftMenuTabIndex: this.leftMenuTabIndex
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'openSpecialTimes') { this.openSpecialDateDialog(); }
      if (result.changeLeftMenuTabIndex) {
        this.changeLeftMenuTabIndex(result.leftMenuTabIndex);
        this.openMenuDialog(result.target);
        return;
      }
      if (result.newScreenId) { this.onChangeScreen(result.newScreenId); }
      if (result.newMediaType.mediaTypeId) { this.openMediaElementDialog(result.newMediaType, {}, false, this.showSpecialTimes); }
      if (result.newMediaFromSearch.mediaTypeId) {
        this.openMediaElementDialog(result.newMediaFromSearch, {}, false, this.showSpecialTimes);
      }
    });
  }


  onDuplicateMediaElement(media, event) {
    event.stopPropagation();
    media.multipleUsed = true;
    this.openMediaElementDialog(media, {}, false, this.showSpecialTimes);
  }

  onDragStart(box) {
    if (this.isMobile()) {
      this.grid.disableDrag();
      this.grid.disableResize();
      return;
    } else {
      this.grid.enableDrag();
      this.grid.enableResize();
      this.boxBeingDragged = true;
      this.activeBoxTimeTableId = '000';
      this.activeBoxTimeTableId = box.timeTableId;
    }

  }

  onDragStop() {
    this.boxBeingDragged = false;
  }

  updateExistedBlock(mediaType, box): boolean {
    if (!box.timeTableId) {
      return false;
    }

    this.activeBoxTimeTableId = box.timeTableId;
    // If the row or sizey has changed, we can identify if
    // box has been moved or resized by mouse
    if (box.row === box.config.row && box.sizey === box.config.sizey) {
      return false;
    }

    box.startTime = this.findTimeByRow(box.config.row);
    box.stopTime = this.findstopTimeByCoords(box.config.row, box.config.sizey);
    // Check new box colides with existing boxes
    if (!this.timetableService.colisionCheck(box.startTime, box.stopTime, box.timeTableId)) {
      // Update locally
      box.row = box.config.row;
      box.sizey = box.config.sizey;
      // Send Update PUT Request & Return
      const data = {
        media: mediaType,
        box: box
      };
      this.updateTimeBox(data);
    } else {
      // reset timetableif colision check returns true
      this.timetableService.updateBoxesWithTimetable();
    }

    return true;
  }

  openMediaElementDialog(mediaType, box, showMediaUsage, showSpecialTimes): void {
    if (this.isTimetableLocked) {
      return;
    }

    this.activeBoxTimeTableId = '000'; // Reset
    this.loading.mediaDialog = true;
    // If function is called through any event (click, move, resize) on an existing box on the timetable

    if (this.updateExistedBlock(mediaType, box)) {
      return;
    }

    const dialogRef = this.dialog.open(MediaElementDialog, {
      width: '800px',
      disableClose: true,
      data: {
        media: mediaType,
        box: box,
        activeScreenId: this.activeScreenId,
        timeTabIndex: this.timeTabIndex,
        showMediaUsage: showMediaUsage,
        showSpecialTimes: showSpecialTimes,
        selectedDate: this.selectedDate,
        company: this.company,
        selectedTime: this.selectedTime
      }
    });
    dialogRef.afterClosed().subscribe(result => {
        this.loading.mediaDialog = false;
        // reset selected time
        this.selectedTime = {
          name: undefined,
          halfHour: undefined
        };
        if (result) {
          if (result.box.timeTableId) {
            // update times (prototype)
            box.config.row = this.timetableService.findRowByTime(result.box.startTime.hour, result.box.startTime.minutes);
            box.row = box.config.row;
            // tslint:disable-next-line:max-line-length
            box.config.sizey = this.timetableService.findSizeYByTime(result.box.startTime.hour, result.box.startTime.minutes, result.box.stopTime.hour, result.box.stopTime.minutes);
            box.sizey = box.config.sizey;
            box.startHour = result.box.startTime.hour;
            box.startMinutes = result.box.startTime.minutes;
            box.endHour = result.box.stopTime.hour;
            box.endMinutes = result.box.stopTime.minutes;
            // update call

            if (box.day.timeTableType.toLowerCase() !== box.timeTableType.toLowerCase() || box.day.timeTableValue !== box.timeTableValue) {
              this.moveTimeBox(result);
            } else {
              this.updateTimeBox(result);
            }
          } else {
            this.createTimeBox(result);
          }
          // refresh recently used / fav medias
          const companyId = this.currentUser.companyId ? this.currentUser.companyId : this.company.companyId;
          this.getRecentMedias(companyId);
          this.getFavoriteMedias(companyId);
        } else {
          // reset timetable
          this.reloadTimetable();
        }
      },
      err => {
        // reset selected time
        this.selectedTime = {
          name: undefined,
          halfHour: undefined
        };
        appLog(err);
      });
  }

  openScreenDialog(screen): void {
    const dialogRef = this.dialog.open(ScreenDialog, {
      width: '300px',
      disableClose: false,
      data: {
        screen: screen
      }
    });
    dialogRef.afterClosed().subscribe(result => {
        if (!screen.screenId) { this.getAllLocations(this.company.companyId); }
      },
      err => {
        // this.getAllLocations(this.company.companyId);
      });
  }

  openSpecialDateDialog(): void {
    const dialogRef = this.dialog.open(SpecialDateDialog, {
      width: '300px',
      disableClose: false,
      data: {
        activeScreenId: this.activeScreenId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.selectedDate) {
          if (this.leftMenuTabIndex === 2) { this.changeLeftMenuTabIndex(1); }
          this.showSpecialTimes = true;
          this.mediaService.setShowSpecialTimes(true);
          this.selectedDate = moment(result.selectedDate);
          this.viewDate = moment(result.selectedDate).local().format('dddd, D. MMMM YYYY');
          this.onChangeSelectedDate(moment(result.selectedDate));
        } else if (result.selectedDates) {
          this.selectedDates = null;
          this.selectedDates = result.selectedDates;
          if (this.leftMenuTabIndex === 2) { this.changeLeftMenuTabIndex(1); }
          this.showSpecialTimes = true;
          this.mediaService.setShowSpecialTimes(true);
          this.selectedDate = moment(result.selectedDates[0]);
          this.viewDate = moment(result.selectedDates[0]).local().format('dddd, D. MMMM YYYY');
          
          this.onChangeSelectedDate(moment(result.selectedDates[0]));
  
        }
      }
    });
  }
}
