
import {filter} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { UserService, LocationsService, TimetableService, MediaService, ApiService, CompaniesService, User, Company, FirebaseService } from '../shared';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatIconRegistry, MatSnackBar} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import { CompanyDialog } from '../dialogs/company-dialog.component';
import { LocationDialogComponent } from '../dialogs/location-dialog.component';
import { ActivatedRoute, Router, Params, NavigationStart } from '@angular/router';
import { SpecialDateDialog } from '../dialogs/special-date-dialog.component';
import { MenuDialog } from '../dialogs/menu-dialog.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  currentUser: User = new User();
  company: Company;
  allCompanies: Array<Company>;
  currentRoute: String = '';
  compId: any;
  locId: any;
  screenId: any;
  activeScreen: any;
  hasSyncData = false;

  @Input() area: string;

  loading = {
    companies: false,
    syncAll: false
  };

  sub1: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    public companiesService: CompaniesService,
    public timetableService: TimetableService,
    public locationsService: LocationsService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public mediaService: MediaService,
    public snackBar: MatSnackBar,
    private firebaseService: FirebaseService
  ) {
    this.currentUser = userService.getCurrentUser();
    this.getAllCompanies();
    // Custom Icons
    iconRegistry.addSvgIcon('logout',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/logout_as.svg')
    );
    // subscribe to active company
    this.companiesService.company.subscribe(
      value => {
        this.company = value;
      }
    );
    this.timetableService.activeScreen.subscribe((screen) => {
      this.activeScreen = screen;
    })
  }

  ngOnInit() {
    this.sub1 = this.firebaseService.hasSyncData.subscribe((has) => {
      this.hasSyncData = has;
    })
  }



  isAuthState() {
    return this.currentRoute.split('/')[1] === 'auth' ? true : false;
  }

  isMobile() {
    return window.innerWidth < 1100;
  }

  switchDashboard(type) {
    const companyId =  this.companiesService.company.value.companyId;
    const locationId = this.route.snapshot.paramMap.get("locationId")

    if (type === 'overview') { this.router.navigateByUrl('overview/' + companyId + '/' + locationId); }
    if (type === 'timeline') { this.router.navigateByUrl('timeline/' + companyId + '/' + locationId ); }
  }

  getAllCompanies() {
    this.loading.companies = true;
    this.companiesService.getAllCompanies()
    .subscribe(
      data => {
        this.allCompanies = data.result;
        this.loading.companies = false;
        this.getCompany();
      },
      () => this.loading.companies = false
    );
  }

  // Initialize company if user has a companyId
  getCompany() {
    let companyId;
    if (this.currentUser.companyId) {
      companyId = this.currentUser.companyId;
    } else if (this.route.snapshot.paramMap.get("companyId")) {
      companyId = this.route.snapshot.paramMap.get("companyId");
    }
    for (let i = 0; i < this.allCompanies.length; i++) {
      if (this.allCompanies[i].companyId === companyId) {
        this.company = this.allCompanies[i];
        this.changeCompany(this.company);
        break;
      }
    }
  }

  changeCompany(company) {
    this.company = company;
    this.companiesService.changeCompany(company);

  }

  routeCompany(company) {
    this.router.navigateByUrl('overview/' + company.companyId);
  }

  openCompanyDialog(company): void {
    const dialogRef = this.dialog.open(CompanyDialog, {
      width: '300px',
      disableClose: false,
      data: {
        company: company
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllCompanies();
    },
    err => {
      this.getAllCompanies();
    });
  }

  openLocationDialog(location): void {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      width: '300px',
      disableClose: false,
      data: {
        location: location,
        company: this.company
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // nil
    });
  }

  startPlayer() {
    window.open(environment.player.baseUrl + environment.player.connectPath +  this.timetableService.activeScreen.value.firestoreUUID, '_blank');
  }

  async syncScreen () {
    this.loading.syncAll = true;
    const screen = this.timetableService.activeScreen.value;
    if (screen.firestoreUUID) {
      await this.firebaseService.syncFirestoreScreen(screen);
      this.snackBar.open('Firestore-Synchronisation erfolgreich.', undefined, {
        duration: 2000
      });
    } 

    this.locationsService.syncAllScreens(screen.companyId).subscribe((res) => {
      this.snackBar.open('Azure-Synchronisation erfolgreich.', undefined, {
        duration: 2000
      });
    })
    

    setTimeout(() => {
      this.loading.syncAll = false;
    }, 1500);
  }

  onLogout() {
    this.userService.logout();
    this.router.navigateByUrl('auth/login');
  }

  openSpecialDateDialog(): void {
    const screenId = this.timetableService.activeScreenId.value;
    const dialogRef = this.dialog.open(SpecialDateDialog, {
      width: '300px',
      disableClose: false,
      data: {
        activeScreenId: screenId,
        viewMode: false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.selectedDate) {
        const locationId = this.route.snapshot.paramMap.get("locationId")
        // tslint:disable-next-line:max-line-length
        this.router.navigateByUrl('timeline/' + this.company.companyId + '/' + locationId + '/' + screenId + '/' + 'specialDate' + '/' + result.selectedDate);
      } else if (result.selectedDates) {
        const locationId = this.route.snapshot.paramMap.get("locationId")
        // tslint:disable-next-line:max-line-length
        this.router.navigateByUrl('timeline/' + this.company.companyId + '/' + locationId + '/' + screenId + '/' + 'specialDate' + '/' + result.selectedDates[0].format("YYYY-MM-DD"));
      }
    });
  }

  openMenuDialog(target) {
    const dialogRef = this.dialog.open(MenuDialog, {
      width: '600px',
      disableClose: false,
      data: {
        target: target,
        showSpecialTimes: this.mediaService.showSpecialTimes,
        activeScreenId: this.mediaService.activeScreenId,
        allLocations: this.locationsService.allLocationsSubject.value,
        newScreenId: undefined,
        mediaTypes: this.mediaService.mediaTypes,
        newMediaType: {},
        newMediaFromSearch: {},
        recentMedias: this.mediaService.recentMedias,
        favoriteMedias: this.mediaService.favoriteMedias,
        leftMenuTabIndex: this.mediaService.leftMenuTabIndex
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // nil
    });
  }



}
