import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { TimelineComponent } from './timeline/timeline.component';
import { OverviewComponent } from './overview/overview.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConnectComponent } from './connect/connect.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardGuard } from './dashboard/dashboard.guard';

const routes: Routes = [
    { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
    { path: 'auth/:authType', component: AuthComponent, canActivate: [AuthGuard] },
    { path: 'connect/:uuid', component: ConnectComponent },
    { path: 'screen/:uuid', component: ConnectComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [DashboardGuard],
        children: [
            { path: 'timeline', component: TimelineComponent, pathMatch: 'full' },
            { path: 'overview', component: OverviewComponent, pathMatch: 'full' },
        ]
    },

    // new timeline: at least the companyId is required
    { path: 'timeline/:companyId', component: TimelineComponent, pathMatch: 'full' },
    { path: 'timeline/:companyId/:locationId', component: TimelineComponent, pathMatch: 'full' },
    { path: 'timeline/:companyId/:locationId/:screenId', component: TimelineComponent, pathMatch: 'full' },
    { path: 'timeline/:companyId/:locationId/:screenId/:mode', component: TimelineComponent, pathMatch: 'full' },
    { path: 'timeline/:companyId/:locationId/:screenId/:mode/:selectedDate', component: TimelineComponent, pathMatch: 'full' },

    // new overview: either accessed with only companyId or also locationId, 
    // if user has not both ids logout, unless 
    // user is sysAdmin: redirect to dashboard (GUARD)
    { path: 'overview/:companyId/:locationId', component: OverviewComponent, pathMatch: 'full' },
    { path: 'overview/:companyId', component: OverviewComponent, pathMatch: 'full' },
    { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
