export const environment = {
    production: false,
    identifier: 'dev',
    languages: ['en', 'de', 'fr', 'it', 'es'],
    api_url: 'https://iascreenmanager.azurewebsites.net',
    azureCDN: 'https://media.screenlime.ch/',
    ffmpeg_url: 'https://ffmpegpublisher.inaffect.one/dev',
    firebase: {
        apiKey: 'AIzaSyCkDEY2bJ9Zrwyv6zrDfLBMphzj8bobgpU',
        authDomain: 'screenlime-dev.firebaseapp.com',
        databaseURL: 'https://screenlime-dev.firebaseio.com',
        projectId: 'screenlime-dev',
        storageBucket: 'screenlime-dev.appspot.com',
        messagingSenderId: '222484523454',
        appId: '1:222484523454:web:1fc675f0ef927790e571ef',
        measurementId: 'G-M05GDZ364Q'
    },
    firestore_api_url: 'https://firestore.googleapis.com/v1/projects/screenlime-dev/databases/(default)/documents/',
    firefunction_api_url: 'https://us-central1-screenlime-dev.cloudfunctions.net/api/',
    player: {
        baseUrl: 'https://player-dev.screenlime.app/',
        connectPath: 'connect/',
      },
};

