import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FirebaseService, CompaniesService } from '../shared';
import { ScreenDialog } from '../dialogs/screen-dialog.component';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {

  uuid: string;
  screenNotFound = false;
  connectCanceled = false;
  isAuthenticated = false;
  dialogOpen = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private firebaseService: FirebaseService,
    private dialog: MatDialog,
    private companiesSerice: CompaniesService
  ) { }

  ngOnInit() {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    console.log("connect to uuid: ", this.uuid)
    if (!this.uuid) {
      this.snackBar.open('UUID ERROR during Connect', null, {duration: 3000});
    }
    if (this.uuid) {
      localStorage.setItem('uuid', this.uuid);
    }
    if (!localStorage.getItem('currentUser')) {
      this.snackBar.open('Bitte zuerst einloggen. Redirecting...', null, {duration: 3000});
      setTimeout(() => {
        this.router.navigateByUrl('auth/login');
      }, 1000);
      
    } else {
      this.isAuthenticated = true;
      this.handleUUIDConnectScreen();
    }

    
  }

  onStartConnect() {
   window.location.reload();
  }

  handleUUIDConnectScreen() {
    this.connectCanceled = false;
    this.screenNotFound = false;
    const c = localStorage.getItem('connectScreen');
    if (c) {
      this.snackBar.open("Weiter mit dem Screen verbinden...", null, {duration: 2500})
      this.openScreenDialog({}, null, null);
      return;
    }
    const uuid = localStorage.getItem('uuid');
    if (uuid) {
      localStorage.removeItem('uuid');
      // check if uuid present on firestore
      this.firebaseService.readScreens(uuid)
        .subscribe(
          fireScreen => {
            const f = fireScreen.fields;
            console.log("screen check rsult:", f)
            if (f.companyId && f.locationId && f.screenId) {
              // is a connected screen, redirect to correct timetable now
              // tslint:disable-next-line:max-line-length
              this.snackBar.open("Screen existiert bereits. Gehe zur Screen " + uuid + " Timeline")
              this.router.navigateByUrl('timeline/' + f.companyId.stringValue + '/' + f.locationId.stringValue + '/' + f.screenId.stringValue);
            } else {
              // is a new screen open connect dialog
              this.snackBar.open("Starte Setup: Neuer Screen", null, {duration: 2500});
              localStorage.setItem('connectScreen', JSON.stringify(fireScreen));
                this.openScreenDialog({}, null, null);
            }
          },
          err => {
            if (err) {
              this.screenNotFound = true;
              // screen not found in firestore TODO SHOW Error
              this.snackBar.open('ERROR: Screen nicht gefunden!', undefined, {
                duration: 3000,
                horizontalPosition: 'start'
              });
            }
          }
        );
    }
  }

  openScreenDialog(screen, locationId, event): void {
    if (this.dialogOpen) { return } // Fix, find out why called multiple times TODO
    this.dialogOpen = true;
    let isNewScreen = false;
    if (!screen.screenId) {
      isNewScreen = true;
    }
    // event.stopPropagation();
    screen.locationId = locationId;
    const dialogRef = this.dialog.open(ScreenDialog, {
      width: '420px',
      disableClose: false,
      data: {
        screen: screen
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogOpen = false;
      console.log(result)
      if (result) {
        if (result.screenId) {
          this.router.navigateByUrl('/timeline/' + result.companyId + '/' + result.locationId + '/' +result.screenId);
        }
      } else {
        // connect canceled
        this.connectCanceled = true;
      }
      
    },
      err => {
        this.dialogOpen = false;
        // this.getAllLocations(this.company.companyId);
      });
  }

}
