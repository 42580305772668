import { MediaItem } from './media';

export class FavoriteMediasFolder {
    companyId: string;
    folderName: string;
    mediaIds: Array<string>;
    isOpen?: boolean;
    favoriteMediasFoldersId?: string;
    isDeleted?: boolean;
    mediaItems?: MediaItem[];
}