import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatIconRegistry, MatSnackBar } from '@angular/material';

@Component({
    selector: 'menu-dialog',
    templateUrl: 'menu-dialog.html',
})
export class MenuDialog implements OnInit {
    activeMenu = {};
    menuOptions = [
        {
            type: 'normal',
            title: 'Spezialzeiten erfassen',
            icon: 'date_range',
            order: 1,
            targetName: 'timetable'
        },
        {
            type: 'normal',
            title: 'Synchronisation',
            icon: 'sync',
            order: 2,
            targetName: 'sync'
        },
        {
            type: 'normal',
            title: 'Location / Screen ändern',
            icon: 'location_on',
            order: 3,
            targetName: 'locations'
        },
        {
            type: 'normal',
            title: 'Neues Media Element',
            icon: 'add_box',
            order: 4,
            targetName: 'new'
        },
        {
            type: 'normal',
            title: 'Neues Module',
            icon: 'view_module',
            order: 5,
            targetName: 'new-module'
        },
        {
            type: 'normal',
            title: 'Vorlagen Durchsuchen',
            icon: 'search',
            order: 6,
            targetName: 'search'
        },
        {
            type: 'normal',
            title: 'Einstellungen',
            icon: 'settings',
            order: 7,
            targetName: 'settings'
        }
    ];
    savingMenu = false;
    leftMenuTabIndex = 0;

    constructor(
        public dialogRef: MatDialogRef<MenuDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        if (this.data.target) {
            for (let i = 0; i < this.menuOptions.length; i++) {
                if (this.data.target === this.menuOptions[i].targetName) {
                    this.activeMenu = this.menuOptions[i];
                }
            }
            if (this.data.target === 'search') { this.data.showSearch = true; }
            if (this.data.target.targetName) { this.activeMenu = this.data.target; }
        }
        this.leftMenuTabIndex = this.data.leftMenuTabIndex;
    }

    changeActiveMenu(option) {
        this.activeMenu = option;
        if (option.targetName === 'timetable') {
            this.data.showSpecialTimes = !this.data.showSpecialTimes;
            this.dialogRef.close('openSpecialTimes');
        }
        if (option.targetName === 'search') {
            this.data.showSearch = true;
        }
        if (option.targetName === 'new') {
            this.leftMenuTabIndex = 1;
            this.data.leftMenuTabIndex = 1;
            this.data.activeMenu = option;
            const tmp = {
                changeLeftMenuTabIndex: true,
                leftMenuTabIndex: 1,
                target: option
            };
            this.dialogRef.close(tmp);
        }
        if (option.targetName === 'new-module') {
            this.leftMenuTabIndex = 2;
            this.data.leftMenuTabIndex = 2;
            const tmp = {
                changeLeftMenuTabIndex: true,
                leftMenuTabIndex: 2,
                target: option
            };
            this.dialogRef.close(tmp);
        }
    }

    clearActiveMenu() {
        this.activeMenu = undefined;
        this.activeMenu = {};
    }

    onConfirm() {
        this.closeMenu();
    }
    closeMenu() {
        this.dialogRef.close(this.data);
    }
    onChangeScreen(screenId) {
        this.data.newScreenId = screenId;
        this.closeMenu();
    }
    openMediaElementDialog(mediaType) {
        this.data.newMediaType = mediaType;
        this.closeMenu();
    }

}
