import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FirestoreApiService } from './firestore.api.service';
import { CompaniesService } from './companies.service';
import { Screen } from '../models/screen.model';
import { FireFunctionsApiService } from './firefunctions.api.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FavoriteMediasFolder } from '../models/favoriteMediasFolder.model';

@Injectable()
export class FirebaseService {
  hasSyncData = new BehaviorSubject(false);
  screenCollectionRef: AngularFirestoreCollection<any>;
  screen$: Observable<any[]>;
  mensamaxMenu: BehaviorSubject<any> = new BehaviorSubject(null);
  mensamaxImage0: BehaviorSubject<any> = new BehaviorSubject(null);
  mensamaxImage1: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private firestore: AngularFirestore,
    private firebaseAPI: FirestoreApiService,
    private fireFunctionsApi: FireFunctionsApiService,
    private companiesService: CompaniesService,
    private analytics: AngularFireAnalytics
  ) { }

  readScreens(uuid) {
    return this.firebaseAPI.get('screens/' + uuid, 'json', false).pipe(
      map(
        data => {
          this.analytics.logEvent('manager_screens_by_uuid_success', { uuid });
          return(data);
        }
      ));
  }

  updateScreenAuthKey(screenUUId, updateData) {
    this.firestore.collection('screens').doc(screenUUId).set({
      authKey: updateData.authKey,
      locationId: updateData.locationId,
      screenId: updateData.screenId,
      companyId: updateData.companyId,
      rotation: updateData.rotation,
      connectedOn: new Date().toISOString()
    }, {merge: true} ).then((res) => {
      this.analytics.logEvent('manager_updateScreenAuthKey_success', { screenUUId, updateData });
    }, (err) => {
      this.analytics.logEvent('manager_updateScreenAuthKey_error', { err });
    });
    const s = this.firestore.collection('screens').doc(screenUUId).get().subscribe(
      (doc) => {
        this.analytics.logEvent('manager_updateScreenAuthKey_screen_subscription_success', { screenUUId });
        this.companiesService.activeFirebaseScreen.next({id: screenUUId, data: doc.data()});
      }
    );
  }
  updateConnectedScreen(screenUUId, updateData) {
    this.firestore.collection('screens').doc(screenUUId).set({
      authKey: updateData.authKey,
      locationId: updateData.locationId,
      screenId: updateData.screenId,
      companyId: updateData.companyId,
      rotation: updateData.rotation,
    }, { merge: true }).then((res) => {
      this.analytics.logEvent('manager_updateConnectedScreen_success', { screenUUId, updateData });
    }, (err) => {
      this.analytics.logEvent('manager_updateConnectedScreen_error', { err });
    });
    const s = this.firestore.collection('screens').doc(screenUUId).get().subscribe(
      (doc) => {
        this.analytics.logEvent('manager_updateConnectedScreen_screen_subscription_success', { screenUUId });
        this.companiesService.activeFirebaseScreen.next({id: screenUUId, data: doc.data()});
      }
    );
  }


  updateScreenTimeTableStamp(uuid) {
    this.firestore.collection('screens').doc(uuid).set({
      timetableChangeOn: new Date().toISOString()
    }, { merge: true }).then((res) => {
      this.analytics.logEvent('manager_updateScreenTimeTableStamp_success', { uuid });
    }, (err) => {
        this.analytics.logEvent('manager_updateScreenTimeTableStamp_error', { err });
    });
  }

  updateScreenMediaStamp(uuid) {
    this.firestore.collection('screens').doc(uuid).set({
      mediaChangeOn: new Date().toISOString()
    }, { merge: true }).then((res) => {
      this.analytics.logEvent('manager_updateScreenMediaStamp_success', { uuid });
    }, (err) => {
      this.analytics.logEvent('manager_updateScreenMediaStamp_error', { err });
    });
  }

  syncFirestoreScreen(screen: Screen) {
    this.hasSyncData.next(false);
    this.updateScreenTimeTableStamp(screen.firestoreUUID);
  }

  syncAllFirestoreScreens(screens: Screen[]) {
    screens.forEach((screen) => {
      if (screen.firestoreUUID) {
        this.updateScreenTimeTableStamp(screen.firestoreUUID);
      }
    });
  }

  getMensaMaxMenuData(id) {
    if (!id) { return; }
    this.firestore.collection('mensamax').doc(id.toString()).get().subscribe((doc) => {
          if (doc.exists) {
              this.analytics.logEvent('manager_mensamax_get_success', { id });
              this.mensamaxMenu.next(doc.data());
          } else {
              this.analytics.logEvent('manager_mensamax_get_not_found', { id });
              // doc.data() will be undefined in this case
              // console.log('No such document!');
          }
      }, (error) => {
          this.analytics.logEvent('manager_mensamax_get_error', { id, error });
          // console.log('Error getting document:', error);
      });
  }

  getMensaMaxMenuImage(id, idx) {
    this.firestore.collection('mensamaxDayMenuPhotos').doc(id + '-' + idx).get().subscribe((doc) => {
          if (doc.exists) {
              this.analytics.logEvent('manager_getMensaMaxMenuImage_success_exists', { id, idx });
              if ( idx === '0') {
                this.mensamaxImage0.next(doc.data());
              }
              if ( idx === '1') {
                this.mensamaxImage1.next(doc.data());
              }
          } else {
              this.analytics.logEvent('manager_getMensaMaxMenuImage_success_not_found', { id, idx });
              // doc.data() will be undefined in this case
          }
      }, (error) => {
        this.analytics.logEvent('manager_getMensaMaxMenuImage_error', { id, idx });
      });
  }

  syncMensaMaxToFirestore(id) {
    return this.fireFunctionsApi.post('mensamax/' + id + '/sync', {}, null, false ).pipe(
      map(
        data => {
          this.analytics.logEvent('manager_syncMensaMaxToFirestore_success', { id });
          return(data);
        }
      ));
  }

  deleteMensaMaxImg(id, idx) {
    return this.fireFunctionsApi.delete('mensamax/' + id + '/photos/' + idx + '/delete', null, false ).pipe(
      map(
        data => {
          this.analytics.logEvent('manager_deleteMensaMaxImg_success', { id, idx });
          return(data);
        }
      ));
  }

  uploadMensaMaxMenuImage(id, idx, filename, file) {
    return this.fireFunctionsApi.uploadWithProgress('mensamax/' + id + '/upload/' + filename + '/' + idx , file, 'json', false).pipe(
      map(
        data => {
          this.analytics.logEvent('manager_uploadMensaMaxMenuImage_success', { id, idx });
          return(data);
        }
      ));
  }

  getAllFavoriteMediaFoldersFromCompany(): Observable<FavoriteMediasFolder[]> {
    const companyId = this.companiesService.company.value.companyId;
    return this.fireFunctionsApi.get('favorite-medias-folders/company/' + companyId, 'json', false).pipe(
      map(
        (data: FavoriteMediasFolder[]) => {
          this.analytics.logEvent('manager_getAllFavoriteMediaFoldersFromCompany_success', { companyId });
          return(data);
        }
      ));
  } 
  
  createFavoriteMediaFolder(folder: FavoriteMediasFolder) {
    const companyId = this.companiesService.company.value.companyId;
    folder.companyId = companyId;
    return this.fireFunctionsApi.post('favorite-medias-folders/', folder, 'json', false).pipe(
      map(
        data => {
          this.analytics.logEvent('manager_createFavoriteMediaFolder_success');
          return(data);
        }
      ));
  }  
  
  
  saveFavoriteMediasFolders(folders: FavoriteMediasFolder[]) {
    const companyId = this.companiesService.company.value.companyId;
    return this.fireFunctionsApi.put('favorite-medias-folders/company/' + companyId, folders, 'json', false).pipe(
      map(
        data => {
          this.analytics.logEvent('manager_saveFavoriteMediaFolders_success');
          return(data);
        }
      ));
  }

}
